import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../api/interceptor";
import { connect } from "react-redux";
import { logout } from "../../store/actionCreators/auth";
import { toggleToast, toggleSpinner } from "../../store/actionCreators/general";
import classNames from "classnames";
// import logoJustSpeed from "../../assets/img/logo-justspeed-header.png";
import logoJustSpeedColor from "../../assets/img/logo-justspeed-header-color.png";
import { headerMenuItems } from "./constants/headerMenuItems";
import { routes } from '../../routing/routes';
import "./Header.scss";

// Creo un array con i path delle pagine statiche
const wrappedRoutes = routes.filter(r => r.isWrapped).map(r => r.path)

function Header({ logout, user, toggleToast, toggleSpinner }) {
  const history = useHistory();
  const dropdownEl = React.useRef(); // Ref per gestire il click-out sul menu a tendina di user

  const [scrollPosition, setScrollPosition] = useState(0); // Tiene traccia dello scrolling
  const [navbarOpen, setNavbarOpen] = useState(false); // tiene traccia dell'apertura del navbar in modalità mobile
  const [dropdownOpen, setDropdownOpen] = useState(false); // tiene traccia dell'apertura del dropdown utente
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false); // tiene traccia dell'apertura del dropdown utente in modalità mobile
  const [, setHeaderConScroll] = useState(true);

  const handleScroll = () => setScrollPosition(window.scrollY);

  // Ottenere la scroll position ad ogni evento scroll per gestire colore della navbar
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [scrollPosition]);

  // Se clicco al di fuori di dropdownEl chiudo la finestra del dropdown del pulsante utente
  function handleClick(e) {
    if (dropdownEl.current && !dropdownEl.current.contains(e.target)) {
      setDropdownOpen(false)
    }
  }

  // Aggiungo un eventListener per reperire dove si trovano i click
  useEffect(() => {
    document.addEventListener('click', handleClick);
  }, [])

  // Logout
  const eseguiLogout = async () => {
    toggleSpinner();
    await axiosInstance
      .post("/logout")
      .then((res) => {
        logout();
        setMobileDropdownOpen(false);
        setNavbarOpen(false);
        history.push("/");
        toggleSpinner();
      })
      .catch((e) => {
        toggleToast("e", e.messaggio);
        toggleSpinner();
      });
  };

  const enterPath = ({ path }) => (event) => {
    event.preventDefault();
    setNavbarOpen(false);
    history.push(path);
  };

  function actionAreaClienti() {
    user ? eseguiLogout() : history.push("/login");
  }

  function toggleNavBar() {
    setMobileDropdownOpen(false);
    setNavbarOpen(!navbarOpen);
  }

  function toggleMobileDropdown() {
    setNavbarOpen(false);
    user ? setMobileDropdownOpen(!mobileDropdownOpen) : history.push('/login')
  }

  // Se sono in una pagina statica disattivo lo scroll sul header
  useEffect(() => {
    history.listen((location, action) => {
      if (wrappedRoutes.includes(location.pathname) || (location.pathname.includes("esito-verifica"))) {
        setHeaderConScroll(false)
      } else {
        setHeaderConScroll(true)
      }
    })
  }, [history])

  // Al primo caricamento del componente verifico se sono in una pagina statica per attivare/disattivare lo scroll sul header
  useEffect(() => {
    if (history?.location?.pathname) {
      if (wrappedRoutes.includes(history.location.pathname)) {
        setHeaderConScroll(false)
      } else {
        setHeaderConScroll(true)
      }
    }
  }, []) // eslint-disable-line

  // const bgIsWhite = scrollPosition !== 0 || navbarOpen || mobileDropdownOpen || !headerConScroll;
  // const bgIsTransparent = scrollPosition === 0 && !navbarOpen && !mobileDropdownOpen && headerConScroll;

  return (
    <nav
      className={classNames("navbar navbar-expand-lg navbar-light w-100 bg-transition",
        {
          "bg-bianco": true,//bgIsWhite,
          // "bg-transparent": bgIsTransparent,
        }
      )}
    >
      <span className="navbar-brand mr-0">
        <img
          height={35} alt=""
          // src={bgIsTransparent ? logoJustSpeed : logoJustSpeedColor}
          src={logoJustSpeedColor}
        />
        <span
          style={{ marginLeft: "-15px" }}
          className={
            "runda-normal text-uppercase font-11 color-linkem-blue"
            // `runda-normal text-uppercase font-11 
            // ${bgIsTransparent ? 'color-bianco' : 'color-linkem-blue'}`
          }
        >
          la fibra di chi vive veloce
        </span>
      </span>

      { /* SEZIONE CON I BUTTONS CHE APPAIONO IN MODE MOBILE: USER BADGE E BURGER */}
      <div>
        <button
          className="navbar-toggler user-badge"
          type="button"
          onClick={toggleMobileDropdown}>
          <i className="far fa-user text-white" />
        </button>

        <button
          className="navbar-toggler"
          type="button"
          style={{ width: '43.5px' }}
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={navbarOpen}
          aria-label="Toggle navigation"
          onClick={toggleNavBar}
        >
          { // Cambio icona burger in base allo stato di apertura di navbar
            navbarOpen ? <i className="fas fa-times" /> : <i className="fas fa-bars" />
          }
        </button>
      </div>

      {/* DROPDOWN UTENTE IN VERSIONE MOBILE */}
      <div className={classNames("mobile-user-dropdown cairoli-font", { 'd-none': !mobileDropdownOpen })}>
        <ul>
          <li className="my-3 text-uppercase cursor-pointer" onClick={() => history.push("/area-personale")}>
            Area Personale
          </li>
          <li className="pt-3 mb-3 text-uppercase cursor-pointer" onClick={eseguiLogout}>
            Logout
          </li>
        </ul>
      </div>


      <div
        className={classNames("collapse navbar-collapse", { 'show text-center pb-3': navbarOpen })}
        id="navbarSupportedContent"
      >
        <ul className={classNames("navbar-nav mr-auto", { "ml-5": !navbarOpen })}>
          {
            /* LINK ITEMS STATICI PROVENIENTI DALLA COSTANTE headerMenuItems */
            React.Children.toArray(
              headerMenuItems.map((item) => (
                <li className="nav-item pt-3">
                  <span className="link-items cairoli-font" onClick={enterPath(item)}>
                    {item.label}
                  </span>
                </li>
              ))
            )
          }
        </ul>

        { /* SEZIONE DROPDOWN USER LOGIN/LOGOUT IN MODALITA' DESKTOP */}
        <span ref={dropdownEl} className="link-items link-item-right">
          { // Visualizzazione diversa del dropdown in funzionedeallo stato di login
            !user
              ? (
                <div className="btn btn-sm btn-linkem-blue border-radius-30 pl-3 pr-4 py-2"
                  style={{ fontSize: '14px' }}
                  onClick={actionAreaClienti}>
                  <i className="far fa-user pr-2" /> Area Clienti
                </div>
              )
              : (
                <div className={classNames("dropdown", { 'show': dropdownOpen })}>
                  <div
                    className="btn btn-sm btn-linkem-blue border-radius-20 pl-3 pr-4 px-2 dropdown-toggle"
                    style={{ fontSize: '14px' }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded={dropdownOpen}
                    onClick={() => setDropdownOpen(!dropdownOpen)}>

                    <i className="far fa-user pr-2" /> Ciao {user.data.name}

                    <div className={classNames("dropdown-menu", { 'show': dropdownOpen })}>
                      <div className="dropdown-item" onClick={() => history.push("/area-personale")}>
                        Area Personale
                      </div>
                      <hr className="mt-1 mb-1 mr-2 ml-2" />
                      <div className="dropdown-item" onClick={eseguiLogout}>
                        Logout
                      </div>
                    </div>

                  </div>
                </div>
              )
          }
        </span>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({ user: state.auth.user });

export default connect(mapStateToProps, { logout, toggleToast, toggleSpinner })(Header);