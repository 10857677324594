import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { convertEuro/*, currencyFormat*/ } from '../../../components/utils/utils';
import Modale from '../../../components/Modale/Modale';
import VerificaCopertura from "../../../components/VerificaCopertura/VerificaCopertura";
import axiosStrapiInstance from "../../../api/strapiInterceptor";
import moment from "moment";
import bollinoFTTH from "../../../assets/img/fibra-bollino.png";
import ReactGA from 'react-ga';
import ButtonLinkem from '../../../components/ButtonLinkem/ButtonLinkem';
import { isMobileDevice } from '../../../utils/utils';
import ContactForm from '../../../components/HomeContactSection/ContactForm/ContactForm';

function RiepilogoOfferta({ inStepper, isVerified, iniziaWorkflow }) {
  const history = useHistory();

  const [hiddenCallModal, setHiddenCallModal] = useState(true);
  const [hiddenVerifyModal, setHiddenVerifyModal] = useState(true);
  const [offerData, setOfferData] = useState(null);
  const [offerSpeed, setOfferSpeed] = useState("fino a 2.5 Gb/s");

  const selectedOffer = useSelector(state => state.selectedOffer);
  const coverage = useSelector(state => state.coverage);

  //Action su button presente solo su mobile
  const onClickAttivaConUnOperatore = () => {
    ReactGA.event({
      category: 'Button',
      action: "Call",
      label: '0289890010'
    });
    const link = document.createElement('a');
    link.setAttribute('href', `tel:+390289890010`);
    link.click();
  };

  const onClickTiChiamiamoGratis = () => {
    ReactGA.event({
      category: 'Button',
      action: "Ti chiamiamo gratis",
      label: history.location.pathname
    });
    setHiddenCallModal(false);
  };


  // Da esito verifica ok si va al funnel
  function iniziaFunnel() {
    ReactGA.event({
      category: 'Button',
      action: "CTA Abbonati Online (cart)",
      label: history.location.pathname
    });
    iniziaWorkflow()
  }

  // Da pagina offerta si apre modale verifica copertura
  function verifyCopertura() {
    ReactGA.event({
      category: 'Button',
      action: "CTA Abbonati Online (cart)",
      label: history.location.pathname
    });
    setHiddenVerifyModal(false)
  }

  useEffect(() => {
    axiosStrapiInstance.get('/offerta-details?offerta_highlight=true', { 'Content-Type': 'multipart/form-data' })
      .then(({ data }) => {
        setOfferData(data[0].offerta_listino[0])
        setOfferSpeed(data[0].offerta_velocita)
      })
  }, [])

  return (
    offerData &&
    <div className="monza-logo-wrapper">

      {/* {
        !isVerified && !inStepper && <img
          src={logoMonza}
          width={170}
          alt=""
          className="monza-logo"
          style={{ margin: "0 2.5rem 0.5rem" }}
        />
      } */}

      <div className="riepilogo-prezzo bg-bianco border-radius-15">
        <div className='py-2 px-1 bg-linkem-blue d-flex justify-content-center align-items-center border-top-left-radius-15 border-top-right-radius-15'>
          <h5 className="text-white text-center text-uppercase title-responsive m-0">
            {inStepper ? "Condizioni dell'offerta" : "abbonati online ora"}
          </h5>
        </div>

        <div className="wrapper-data-riepilogo px-5 py-3">
          <div className='pb-2 wrapper-data-with-border'>
            <h5 className="color-linkem-blue runda-bold text-uppercase">
              costi mensili
            </h5>
            <div className='mt-2 riepilogo-items-container'>
              <p className="color-linkem-blue text-uppercase m-0">
                canone {selectedOffer?.head_name}
              </p>
              <p className="m-0 runda-bold" style={{ textDecoration: 'line-through', color: '#9BABB9' }}>
                {convertEuro(selectedOffer?.standard_amount)}/mese
              </p>
            </div>

            <div className="riepilogo-items-container my-2 align-items-center">
              <div className="adaptable-col-left">
                <div style={{ width: 'fit-content', cursor: 'default' }}
                  className="btn btn-sm bg-linkem-red runda-bold text-white text-uppercase py-1 px-3 border-radius-30">
                  {selectedOffer?.sub_name}
                </div>
              </div>
              <div className="adaptable-col-right">
                <p className="color-linkem-blue runda-bold m-0">
                  {convertEuro(selectedOffer?.discount_amount > 0 ? selectedOffer?.discount_amount : selectedOffer?.monthly_amount)}/mese
                </p>
                {
                  selectedOffer?.discount_months > 0 ?
                    <small className="runda-light m-0">
                      per i primi {selectedOffer?.discount_months} mesi
                    </small>
                    : selectedOffer?.trial_days > 0 ?
                      <small className="runda-light m-0">
                        gratuito per i primi {selectedOffer?.trial_days} giorni
                      </small>
                      : ""
                }
              </div>
            </div>
            <p className="color-linkem-blue mb-0">
              <img className="mr-2" src={bollinoFTTH} alt='' height={30} />
              {offerSpeed}
            </p>
          </div>
          <div className='pt-2 wrapper-data-with-border'>
            <h5 className='mb-2 color-linkem-blue runda-bold text-uppercase'>
              dettagli
            </h5>
            {
              offerData.ListinoDetails.length > 0 &&
              React.Children.toArray(offerData.ListinoDetails.map(el => (

                <div className="row m-0 mb-2 justify-content-between">
                  <div className="col-sm-8 col-xs-12 px-0 d-flex flex-column justify-content-start">
                    <p className="opzioni-offerta color-linkem-blue runda-light text-uppercase m-0">
                      {el.titolo}
                    </p>
                    {
                      el.sottotitolo && <small className="runda-light">
                        {el.sottotitolo}
                      </small>
                    }
                  </div>
                  <div className="col-sm-4 col-xs-12 px-0 d-flex justify-content-end">
                    <p className="color-linkem-red font-weight-bold m-0" style={{ lineHeight: 'normal' }}>
                      {
                        el.prezzo_iniziale && (
                          <span
                            className="runda-light mr-2"
                            style={{ textDecoration: 'line-through', color: '#9BABB9' }}
                          >
                            {el.prezzo_iniziale}
                          </span>
                        )}
                      {el.prezzo}
                    </p>
                  </div>
                </div>
              )))
            }
          </div>
          <div className='py-2'>
            <div className="row justify-content-between">
              <div className="col-md-4 col-sm-12">
                <h5 className="color-linkem-blue runda-bold text-uppercase mt-1">
                  totale
                </h5>
              </div>
              <div className="d-flex justify-content-end col-md-8 col-sm-12">
                <h4 className="runda-bold color-linkem-red m-0">
                  {convertEuro(selectedOffer?.discount_amount > 0 ? selectedOffer?.discount_amount : selectedOffer?.monthly_amount)}/mese
                </h4>
              </div>
              <div className="col-12 d-flex flex-column justify-content-end">
                {
                  selectedOffer?.lines.map((el, idx) => idx !== selectedOffer?.lines.length - 1
                    ? <small key={idx} className="color-linkem-red runda-light text-right m-0">{el} <br /></small>
                    : <small key={idx} className="color-linkem-red runda-light text-right m-0">{el}</small>
                  )
                }
              </div>
            </div>
          </div>

          {   /*Nascondere questa sezione se stiamo nello stepper. 
                Inoltre se siamo su esito verifica ok
                il pulsante rimanda al primo step del workflow */
            !inStepper && (
              <>
                {
                  isMobileDevice() && (
                    
                    <ButtonLinkem
                      isSmall
                      linkemRed
                      className="w-100 d-flex align-items-center justify-content-center border-radius-30 px-2 py-2 mb-2"
                      onClick={onClickAttivaConUnOperatore}
                    >
                      <i className="fas fa-phone-volume mr-2" style={{ fontSize: '20px' }} />
                      <span className="termina-demi pt-1">
                        attiva con un operatore
                      </span>
                    </ButtonLinkem>
                  )
                }

                <ButtonLinkem
                  isSmall
                  linkemRed
                  className="w-100 d-flex align-items-center justify-content-center border-radius-30 px-2 py-2 mb-2"
                  onClick={onClickTiChiamiamoGratis}
                >
                  <i className="fas fa-envelope mr-3" style={{ fontSize: '20px' }} />
                  <span className="termina-demi pt-1">
                    ti chiamiamo gratis
                  </span>
                </ButtonLinkem>

                {/*<ButtonLinkem*/}
                {/*  isSmall*/}
                {/*  linkemRed*/}
                {/*  className="w-100 d-flex align-items-center justify-content-center border-radius-30 px-4 py-2"*/}
                {/*  onClick={() => isVerified ? iniziaFunnel() : verifyCopertura()}*/}
                {/*>*/}
                {/*  <i className="fas fa-shopping-cart mr-3" style={{ fontSize: '20px' }} />*/}
                {/*  <span className="termina-demi pt-1">*/}
                {/*    abbonati online*/}
                {/*  </span>*/}
                {/*</ButtonLinkem>*/}
              </>
            )
          }

          <h6 className="color-linkem-blue runda-light mt-2 text-center">
            <i className="far fa-clock mr-2" />
            offerta valida fino al {selectedOffer.valid_to && moment(selectedOffer.valid_to).format(('DD/MM/YYYY'))}
          </h6>
        </div>

        {!inStepper &&
          <Modale
            hidden={hiddenVerifyModal}
            bgColor='bg-linkem-blue'
            dismissButtonClass='absolute-close-button'
            displayDismissButton={true}
            setToggleModale={() => setHiddenVerifyModal(true)}>
            <VerificaCopertura
              columnMode={true}
              setToggleModale={() => setHiddenVerifyModal(true)}
            />
          </Modale>}

        {!inStepper && (
          <Modale
            hidden={hiddenCallModal}
            displayDismissButton={true}
            bgColor='bg-linkem-blue'
            dismissButtonClass='absolute-close-button'
            setToggleModale={() => setHiddenCallModal(true)}>
            <ContactForm isModale={true}
              title={coverage ? 'Vuoi attivare l’offerta con il supporto di un nostro operatore?' : 'Lasciaci i tuoi dati'}
              subtitle={coverage ? 'Lasciaci i tuoi dati e sarai ricontattato al più presto' : null}
              bgColor='bg-linkem-blue'
              textColor="text-white"
              coverage={coverage} />
          </Modale>
        )}
      </div>
    </div>
  )
};

RiepilogoOfferta.propTypes = {
  inStepper: PropTypes.bool,
  isVerified: PropTypes.bool,
  iniziaWorkflow: PropTypes.func
}

export default RiepilogoOfferta;
