import axiosInstance from '../interceptor';

const api = '/coverage';

export const getComune = (request) => axiosInstance.post(`${api}/city`, request, { 'Content-Type': 'multipart/form-data' });

export const getIndirizzo = (request) => axiosInstance.post(`${api}/street_name`, request, { 'Content-Type': 'multipart/form-data' });

export const getCivico = (request) => axiosInstance.post(`${api}/address_number`, request, { 'Content-Type': 'multipart/form-data' });

export const getCopertura = (request) => axiosInstance.post(`${api}/check`, request, { 'Content-Type': 'multipart/form-data' });