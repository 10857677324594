const { REACT_APP_BASENAME_STRAPI } = process.env;

export const createImagePath = imageURL => REACT_APP_BASENAME_STRAPI.concat(imageURL);

export const isMobileDevice = () =>
  (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));


export const HOME_BANNER_CITIES = [
  { citta: 'Nazionale', heading: 'SBRIGATI!', sottotitolo: 'FIBRA VELOCE, VITA VELOCE.' },
  { citta: 'Milano', heading: 'MÖVES!', sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A MILANO.' },
  { citta: 'Palermo', heading: "CATAMÌATI!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A PALERMO.' },
  { citta: 'Torino', heading: "BÙGIA!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A TORINO.' },
  { citta: 'Bari', heading: "MUV'T!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A BARI.' },
  { citta: 'Bergamo', heading: "MÖES FÒ!", sottotitolo: ' FIBRA VELOCE, VITA VELOCE. ANCHE A BERGAMO.' },
  { citta: 'Catania', heading: "ALLÈSTITI!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A CATANIA.' },
  { citta: 'Cagliari', heading: "MOVIRINDI!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A CAGLIARI.' },
  { citta: 'Genova', heading: "MESCITE!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A GENOVA.' },
  { citta: 'Lecce', heading: "MANISCIATE!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A LECCE.' },
  { citta: 'Terni', heading: "MÒVETE!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A TERNI.' },
  { citta: 'Cosenza', heading: "MANÌATI!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A COSENZA.' },
  { citta: 'Matera', heading: "SPÙCC'T!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A MATERA.' },
  { citta: 'Treviso', heading: "MOVATE!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A TREVISO.' },
  { citta: 'Campobasso', heading: "MUOV'T!", sottotitolo: 'FIBRA VELOCE, VITA VELOCE. ANCHE A CAMPOBASSO.' }
];