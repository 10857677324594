import { SAVE_PRIVACY_URL, SAVE_PRIVACY_LEAD1_URL, SAVE_PRIVACY_LEAD12_URL } from '../actions/privacy';

const initialState = {
  privacyContract: "",
  privacyLeadOneMonth: "",
  privacyLeadTwelveMonths: ""
}

export const privacy_url = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PRIVACY_URL:
      return { ...state, privacyContract: action.url };
    case SAVE_PRIVACY_LEAD1_URL:
      return { ...state, privacyLeadOneMonth: action.url };
    case SAVE_PRIVACY_LEAD12_URL:
      return { ...state, privacyLeadTwelveMonths: action.url };
    default:
      return state;
  }
}