import React from "react";
import { useHistory } from "react-router-dom";
import "./StaticPageLayout.scss";

function StaticPageLayout({ children }) {

  const propsParent = Object.assign({}, children["props"], {
    location: children["props"]["location"],
  });
  const childrenWithProps = React.Children.map(children, (child, _) =>
    React.cloneElement(child, { propsParent })
  );
  const history = useHistory();


  return (
    <div className="container-fluid static-page-container">
      <div className="static-page-background" />
      <div className="row mt-3 justify-content-center adaptable-padding bg-bianco">
        <div className="col-12 static-page-text-container">
          {childrenWithProps}
          <button
            className="btn btn-secondary py-2 px-4 my-3 border-radius-20 font-weight-bold"
            onClick={() => history.goBack()}
          >
            Indietro
        </button>
        </div>
      </div>
    </div>
  );
}

export default StaticPageLayout;
