// Verifica formato codice fiscale
export function validaCodiceFiscale(cf) {
  var validi, i, s, set1, set2, setpari, setdisp;
  if (cf === '') return '';
  cf = cf.toUpperCase();
  if (cf.length !== 16)
    return false;
  validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  for (i = 0; i < 16; i++) {
    if (validi.indexOf(cf.charAt(i)) === -1)
      return false;
  }
  set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
  s = 0;
  for (i = 1; i <= 13; i += 2)
    s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
  for (i = 0; i <= 14; i += 2)
    s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
  if (s % 26 !== cf.charCodeAt(15) - 'A'.charCodeAt(0))
    return false;
  return true;
}

// Get Cookie by name
export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// Da due numeri M e N creo un array che va da M a N
export function createArray(num1, num2) {
  let myArr = [];
  for (let i = num1; i <= num2; i++) {
    myArr.push(i)
  };
  return myArr
}

// regexp per escludere i caratteri non numerici. Testabile tramite regexp.test(value)
export const regexp = /^[0-9\b]+$/;

// regexp per escludere i caratteri che non corrispondono a lettere. Testabile tramite regexpLetters.test(value)
export const regexpLetters = /^[A-Za-z\b]+$/;

// Metodo per aggiungere il simbolo dell'euro a un numero
export const currencyFormat = {
  it: 'it-IT',
  en: 'en-US'
}

export const convertEuro = (num, format) => {
  return new Intl.NumberFormat(format, { style: 'currency', currency: 'EUR' }).format(num)
}

// Metodo per verificare che tutte le chiavi di un oggetto siano non null o ""
export function checkObjectValues(obj) {
  for (var key in obj) {
    if (!obj[key] || obj[key].length === 0)
      return false;
  }
  return true;
}

// Metodo per verifica numero cellulare
export function checkCellNum(cellNumber) {
  const prefissi = [
    '313',
    '320', '322', '323', '324', '327', '328', '329',
    '330', '331', '333', '334', '335', '336', '337', '338', '339',
    '340', '341', '342', '343', '344', '345', '346', '347', '348', '349',
    '350', '351', '352', '353', '355',
    '360', '361', '362', '363', '366', '368',
    '370', '371', '373', '375', '376', '377', '378', '379',
    '380', '382', '383', '388', '389',
    '390', '391', '392', '393', '397'
  ];

  const prefix = cellNumber.slice(0, 3);
  const postPrefix = cellNumber.slice(3, cellNumber.length);

  const res = prefissi.includes(prefix) && ((postPrefix.length === 6) || (postPrefix.length === 7))
  return res;
}

// Metodo per verifica numero fisso
export function checkPhone(phoneNumber) {
  const firstDigit = phoneNumber[0];
  const otherDigits = phoneNumber.slice(1, phoneNumber.length);

  const res = firstDigit === '0' && otherDigits.length > 5 && otherDigits.length < 13
  return res;
}

// Metodo per verifica IBAN
export function checkIban(iban) {
  // const cleanIban = iban.split(' ').filter(el => el).join('');
  // const regexp = /^[0-9\b]+$/;
  // const regexpLetters = /^[A-Za-z\b]+$/;
  // const country = cleanIban.slice(0, 2) === 'IT';
  // const preCin = regexp.test(cleanIban.slice(2, 4));
  // const cin = regexpLetters.test(cleanIban[4]);
  // const postCIN = regexp.test(cleanIban.slice(5, cleanIban.length));
  // const ibanLength = cleanIban.length === 27;
  // return (country && preCin && cin && postCIN && ibanLength)
  const cleanIban = iban.split(' ').filter(el => el).join('');
  const regexp = /^(it|IT)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}$/;
  return regexp.test(cleanIban)
}

// Metodo per verifica Carta Identità
export function checkCartaIdentita(numCarta) {
  const regexp = /^[0-9\b]+$/;
  const regexpLetters = /^[A-Za-z\b]+$/;

  // per carta cartacea con formato 2 lettere - 7 numeri
  const prefixCartaceo = regexpLetters.test(numCarta.slice(0, 2));
  const postPrefixCartaceo = regexp.test(numCarta.slice(3, numCarta.length));
  const cartaceoLength = numCarta.length === 9;
  const checkCartaceo = (prefixCartaceo && postPrefixCartaceo && cartaceoLength)

  // per carta elettronica con formato C<lettera><numero><numero><numero><numero><numero><lettera><lettera>
  const inizioElettronico = numCarta[0] === 'C';
  const secondaElettronico = regexpLetters.test(numCarta.slice(1, 2));
  const numsElettronico = regexp.test(numCarta.slice(2, 7));
  const lastElettronico = regexpLetters.test(numCarta.slice(7, 9));
  const elettronicoLength = numCarta.length === 9;
  const checkElettronico = (inizioElettronico && secondaElettronico && numsElettronico && lastElettronico && elettronicoLength)

  return (checkCartaceo || checkElettronico)
}

// Metodo per verifica Passaporto
export function checkPassaporto(numPassaporto) {
  const regexp = /^[0-9\b]+$/;
  const regexpLetters = /^[A-Za-z\b]+$/;

  // Formato 2 lettere - 7 numeri
  const prefixPassaporto = regexpLetters.test(numPassaporto.slice(0, 2));
  const postPrefixPassaporto = regexp.test(numPassaporto.slice(3, numPassaporto.length));
  const passaportoLength = numPassaporto.length === 9;
  const checkPassaporto = (prefixPassaporto && postPrefixPassaporto && passaportoLength)

  return (checkPassaporto)
}

// Metodo per verifica Patente
export function checkPatente(numPatente) {
  const regexp = /^[0-9\b]+$/;
  const regexpLetters = /^[A-Za-z\b]+$/;
  const regexpAlfanumeric = /^[a-zA-Z0-9\b]+$/;

  // Formato vecchie patenti cartacee 1 lettera + 7 numeri
  const patenteCartaceaFirst = regexpLetters.test(numPatente[0])
  const patenteCartaceaRemaining = regexp.test(numPatente.slice(1, numPatente.length))
  const patenteCartaceaLength = numPatente.length === 8;
  const checkPatenteCartacea = patenteCartaceaFirst && patenteCartaceaLength && patenteCartaceaRemaining

  // Formato vecchie patenti card 2 lettere + 7 numeri + 1 lettera
  const prefixPatenteCard = regexpLetters.test(numPatente.slice(0, 2));
  const postPrefixPatenteCard = regexp.test(numPatente.slice(3, numPatente.length - 1));
  const lastPatenteCard = regexpLetters.test(numPatente[numPatente.length - 1]);
  const patenteCardLength = numPatente.length === 10;
  const checkPatenteCard = prefixPatenteCard && postPrefixPatenteCard && lastPatenteCard && patenteCardLength

  // Formato Patente attuale U1 + 7 caratteri alfanumerici + 1 lettera
  const prefixPatente = (numPatente.slice(0, 2) === 'U1');
  const lastDigitPatente = regexpLetters.test(numPatente[numPatente.length - 1]);
  const postPrefixPatente = regexpAlfanumeric.test(numPatente.slice(3, numPatente.length - 1));
  const patenteLength = numPatente.length === 10;
  const checkPatente = (prefixPatente && lastDigitPatente && postPrefixPatente && patenteLength)

  return (checkPatente || checkPatenteCard || checkPatenteCartacea)
}

// Conversione data a YYYY-MM-DD
export function convertDateUs(str) {
  const date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

// Conversione data a DD/MM/YYYY
export function convertDateIt(str, symbol) {
  const date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join(!symbol ? "/" : symbol);
}

// Conversione di single number a stringa a due digits. Es. 5 => '05'
export function numDoubleDigit(num) {
  const stringNum = num.toString();
  return stringNum.length > 1 ? stringNum : '0' + stringNum
}

// Metodo di verifica formato email 
export function ValidateEmail(mail) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,})+\.)+[a-zA-Z]{2,}))$/;
  return re.test(mail.toLowerCase());
}

// Metodo per rimuovere particella toponomastica da un indirizzo
export function removeToponomastic(str) {
  const elenco = ['circonvallazione', 'contrada', 'corso', 'galleria', 'giardino', 'largo', "localita'", "località", 'parco', 'passaggio', 'piazza', 'piazzale', 'piazzetta', 'porta', 'rotonda', 'salita',
    'scaletta', 'scalinata', 'scalone', 'sentiero', 'sovrappasso', 'strada', 'via', 'viadotto', 'viale', 'vicolo'];

  const temp = str.split(' ').filter(el => el)
  
  if (temp.length === 0) {
    return ""
  }
  
  else if (temp.length === 1) {
    return temp[0]
  }

  else {
    const temp1 = temp[0].toLowerCase();
    return elenco.includes(temp1) ? temp.filter((el, idx) => idx !== 0).join(' ') : str
  }
}

// Metodo per normalizzare i caratteri, sostituendo lettere accentate
export function normalizeString(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}