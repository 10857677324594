import axiosInstance from "../interceptor";

const api = '/leads/create';

export const FETCH_TYPES = {
  activeLine: 'active-line',
  coverageOk: 'coverage-ok',
  coverageKo: 'coverage-ko',
  callMe: 'call-me'
};

export const createLead = async (tipoCall, body) =>
  await axiosInstance.post(`${api}/${tipoCall}`, body, { 'Content-Type': 'multipart/form-data' });