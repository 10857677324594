import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axiosStrapiInstance from '../../api/strapiInterceptor';
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyUrl, getPrivacyLeadOneUrl, getPrivacyLeadTwelveUrl } from '../../store/actionCreators/privacy';
import { authCheckState, authSuccess } from "../../store/actionCreators/auth";
import { toggleToast } from '../../store/actionCreators/general';
import { getOffers, setSelectedOffer } from '../../store/actionCreators/commercialOffers';
import { ToastContainer } from 'react-toastify';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { getRoutes, scrollTop } from '../../routing/utils';
import { getCookie } from '../utils/utils';
import axiosInstance from '../../api/interceptor';
import Layout from "../Layout/Layout";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ReactGA from "react-ga";
// import TagManager from 'react-gtm-module';
// import { tagManagerArgs, googleTagManagerEvents } from '../utils/googleAnalytics';
import ReactPixel from 'react-facebook-pixel';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { routes } from '../../routing/routes';
import { getCrmOffer } from '../../api/crmOffer/crmOffer';

// INIZIALIZZAZIONE DI GOOGLE TAG MANAGER
// TagManager.initialize(tagManagerArgs) TEMP removed

const CloseButton = ({ closeToast }) => (
  <i
    className="fa fa-times-circle"
    onClick={closeToast}
  />
);

function App() {

  const user = useSelector(({ auth }) => auth.user);
  const isLoading = useSelector(({ general }) => general.loading);
  const dispatch = useDispatch();

  const selectedOffer = useSelector(state => state.selectedOffer);

  // Chiamata al BE per verificare se l'utente è loggato. Se lo è salviamo tutti i dati in redux e sessionStorage
  async function isLogged() {
    await axiosInstance.get('/crm/info')
      .then(({ data }) => {
        // const oreExpiration = 2;
        // const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
        // sessionStorage.setItem('expiration', expirationDate);
        sessionStorage.setItem('user', btoa(JSON.stringify(data)));
        dispatch(authSuccess(data));
      })
      .catch(() => {
        console.log('Utente non autenticato');
        sessionStorage.removeItem('user') // Se l'utente non è autenticato rimuovo eventuali dati su di lui nel session storage
      })
  }

  // Chiamata effettuata al primo caricamento dell'app per settare le cookis di sessione
  async function getSessionCookie() {
    await axiosInstance.get('/sanctum/csrf-cookie')
      .then(() => console.log('Session cookies set'))
      .catch(e => dispatch(toggleToast('e', e.messaggio)))
  }

  // CHIAMATA EFFETTUATA AL PRIMO CARICAMENTO PER OTTENERE L'URL DEI PDF SU PRIVACY
  async function getPrivacyPdf() {
    await axiosStrapiInstance.get('/pdf-privacy')
      .then(res => {
        if (res.data.privacy.url) dispatch(getPrivacyUrl(res.data.privacy.url))
      })
  }

  async function getPrivacyLeadOnePdf() {
    await axiosStrapiInstance.get('/pdf-privacy-lead-1-m')
      .then(res => {
        if (res.data.privacy_lead_1_month.url)
          dispatch(getPrivacyLeadOneUrl(res.data.privacy_lead_1_month.url))
      })
  }

  async function getPrivacyLeadTwelvePdf() {
    await axiosStrapiInstance.get('/pdf-privacy-lead-12-m')
      .then(res => {
        if (res.data.privacy_lead_12_months.url)
          dispatch(getPrivacyLeadTwelveUrl(res.data.privacy_lead_12_months.url))
      })
  }

  async function selectCrmOffer() {
    getCrmOffer().then(res => {
      const offers = res.data.data.filter(el => el.default);
      dispatch(getOffers(offers));
      if (offers?.length > 0 && selectedOffer?.isMock) {
        dispatch(setSelectedOffer(offers[0]));
      }
    })
  }

  // A OGNI CAMBIO DI PAGINA SI FA SCROLL TO TOP, SI VERIFICA SE CI SONO I COOKIES 
  // E SI SALVA IL PATH NEL SESSION STORAGE E LO SI INOLTRA A GOOGLE ANALYTICS
  const history = useHistory();

  useEffect(() => {
    history.listen((location) => {
      scrollTop();

      // invio del path a Google Analytics a ogni cambio pagina
      // googleTagManagerEvents("pageview", location.pathname); TEMP removed
      ReactGA.pageview(location.pathname);

      // Fetch dei cookies di Nexev se non ce ne sono di precedenti
      if (!getCookie('XSRF-TOKEN')) getSessionCookie();

      // Se non sono in componenti dello stepper resetto l'offerta selezionata
      if (!routes.filter(el => el.isStepper || el.isOfferPage).some(el => el.path.includes(location.pathname))) selectCrmOffer();
    })
  }, [history]) // eslint-disable-line


  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA); // Inizializzazione Google Analytics 
    ReactPixel.init(process.env.REACT_APP_FACEBOOK, {}, { debug: true, autoConfig: false }); // Inizializzazione Facebook Pixel
    ReactPixel.pageView(); // Inizializzazione Facebook Pixel
    ReactPixel.fbq('track', 'PageView'); // Inizializzazione Facebook Pixel

    //ReactPixel.revokeConsent();
    isLogged(); // Se abbiamo cookies di sessione salviamo i dati nel session storage
    dispatch(authCheckState()); // Verifichiamo la data di scadenza della session per fare partire un timeout per il logout
    getPrivacyPdf();
    getPrivacyLeadOnePdf();
    getPrivacyLeadTwelvePdf();
    selectCrmOffer();

    // Fetch dei cookies di Nexev se non ce ne sono di precedenti
    if (!getCookie('XSRF-TOKEN')) getSessionCookie();
  }, []); // eslint-disable-line

  useEffect(() => {
    //Onetrust
    window.addEventListener('DOMContentLoaded', () => {
      const timeId = setInterval(() => {
        if (window.OneTrust) {
          window.addEventListener('consent.onetrust', () => {
            window.location.reload();
          })
          clearInterval(timeId);
        }
      }, 2000)
    })
  }, [])

  return (
    <>
      <div id="app-container">
        <Header />
        {!user ? getRoutes(user) : <Layout user={user} />}
        {/* {!getCookie('rcl_consent_given') && <BannerCookie />} */}
        <Footer />
      </div>

      <ToastContainer autoClose={5000} closeButton={CloseButton} />
      {isLoading && <LoadingSpinner />}
    </>
  );
}

export default App;
