import React from 'react';
import { activationStepsLabels } from '../../store/constants/activationSteps';
import classNames from 'classnames';
import './Stepper.scss';

function Stepper({ data }) {
  return (
    <div className="stepper-container">

      {data.map((el, idx) => (
        <div key={idx} className="bollini-container">
          { idx < data.length - 1 && <div className="white-line" />}
          <div className={classNames("bollino bollino-grande", { 'active': el.currentlyActive })}>
            <div className={classNames("bollino", { 'active': el.currentlyActive, "bollino-rosso": el.labelStepper !== activationStepsLabels.ATTIVAZIONE, "envelope": el.labelStepper === activationStepsLabels.ATTIVAZIONE})}>
              {el.labelStepper === activationStepsLabels.ATTIVAZIONE && <i className="far fa-envelope" />}
            </div>
          </div>
          <div className={classNames("text-uppercase text-center mt-3", { 'text-white active': el.currentlyActive, 'text-white-50': !el.currentlyActive })}>
            {el.labelStepper}
          </div>
        </div>
      ))}

    </div>
  )
}

export default Stepper
