import { SET_COVERAGE } from '../actions/coverage';

export const coverage = (state = {}, action) => {
  switch (action.type) {
    case SET_COVERAGE: {
      return { ...action.payload}
    }

    default: return { ...state };
  }
};