import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import StaticPageLayout from '../components/StaticPageLayout/StaticPageLayout';
import { routes } from './routes';
import Sottoscrizione from '../pages/Sottoscrizione/Sottoscrizione';

const getVisibleToAllRoutes = () => routes.filter(r => r.access.includes(0));
const getPublicRoutes = () => routes.filter(r => r.access.includes(1));
const getPrivateRoutes = () => routes.filter(r => r.access.includes(2));
const getPrivateRoutesWithRoles = (authorities) => routes.filter(r => r.access.includes(3) &&
  authorities &&
  r.roles.some(r => authorities.indexOf(r) >= 0));

function CustomComponent(Component) {
  return function (props) {
    return <Component {...props} />;
  }
}

// WRAPPER FOR STATIC PAGES
export const StaticPageComponent = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <StaticPageLayout>
        <Component {...Object.assign({}, matchProps, { location: { ...matchProps["location"], identity: rest["identity"] } })} />
      </StaticPageLayout>
    )} />
  )
};

// WRAPPER FOR WORKFLOW STEPPER COMPONENTS
export const StepperComponent = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <Sottoscrizione>
        <Component {...matchProps} />
      </Sottoscrizione>
    )} />
  )
};

// Creazione di Route con eventuali wrappers per ogni componente
const getRouteComponent = routes => routes.map((r, i) => (
  r.isWrapped ? <StaticPageComponent path={r.path} exact key={i} component={r.component} {...r} />
    : r.isStepper ? <StepperComponent path={r.path} exact key={i} component={r.component} />
      : <Route path={r.path} exact key={i}>{CustomComponent(r.component)}</Route>
));

export const getRouteItems = user =>
  user ?
    getVisibleToAllRoutes().concat(getPrivateRoutesWithRoles(user.ruoloUtente), getPrivateRoutes()) :
    getVisibleToAllRoutes().concat(getPublicRoutes());

export const getRoutes = (user) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      {getRouteComponent(getRouteItems(user))}
      {/*<Redirect to={user ? sessionStorage.getItem('path') : '/'} /> { /* prevedere un redirect verso area personale alternative a path in storage / }*/}
      <Redirect to="/" /> { /* prevedere un redirect verso area personale alternative a path in storage */}
    </Switch>
  </Suspense>
);

export const getSiteMap = routeItems => routeItems.filter(r => r.inMappaSito).map(r => ({ path: r.path, name: r.name }))

/*
    Scroll automatico al cambio pagina
 */
export const scrollTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, 200);
}

// Lista toponimi
export const toponimi = [
  "ACCESSO ",
  "AEROPORTO",
  "BALUARDO",
  "AEREOSCALO",
  "AIUOLA",
  "ALTO",
  "ALZAIA",
  "ANDRONA",
  "ANDRONE",
  "ANGIPORTO",
  "ARCHIVOLTO",
  "ARCO",
  "ARGINE",
  "ASCENSORE",
  "ATRIO",
  "AUTOSTRADA",
  "BACINO",
  "BAGLIO",
  "BANCHINA",
  "BATTERIA",
  "BARBARIA",
  "BASTIONE",
  "BELVEDERE",
  "BIRI",
  "BIVIO",
  "BOCCA",
  "BORGO",
  "BORGOLOCO",
  "BOSCO",
  "BORGATA",
  "BORGHETTO",
  "CA'",
  "CALA",
  "CALATA",
  "CALLE",
  "CALLESELLA",
  "CAMPIELLO",
  "CAMPANILE",
  "CAMPAZZO",
  "CAMPEGGIO",
  "CAMPO",
  "CANALE",
  "CANCELLO",
  "CANTO",
  "CANTONE",
  "CAPO",
  "CAPPELLINI",
  "CARRAIA",
  "CASALE",
  "CASALI",
  "CASE",
  "CASEGGIATO",
  "CASTELLO",
  "CAVA",
  "CAVONE",
  "CONTRADA",
  "CHIASSO",
  "CHIESA",
  "CIMA",
  "CITTA'",
  "CORTILE",
  "CLIVO",
  "CASELLO",
  "CASCINA",
  "CIRCONVALLAZIONE",
  "CENTRO",
  "COLLINA",
  "COLLE",
  "CONSELVANA",
  "CONTEMPLAZIONE",
  "CONTRA'",
  "CORSETTO",
  "CORSIA",
  "CORSO",
  "CORTE",
  "CORTICELLA",
  "COSTA",
  "CROCEVIA",
  "CROSINO",
  "CROSA",
  "CASCINE",
  "CASCINALE",
  "CUPA",
  "CURVONE",
  "CAVALCAVIA",
  "DARSENA",
  "DEPOSITO",
  "DIGA",
  "DIRAMAZIONE",
  "DISCESA",
  "DISTACCO",
  "EMICICLO",
  "ERTA",
  "ESEDRA",
  "FABBRICATO",
  "FONDACO",
  "FONDAMENTA",
  "FONDO",
  "FONTANELLA",
  "FONTEGO",
  "FORO",
  "FORTE",
  "FOSSATO",
  "FOSSA",
  "FOSSO",
  "FRAZIONE",
  "GALLERIA",
  "GIARDINO",
  "GIARDINETTI",
  "GRADINI",
  "GRADINATA",
  "GRADONI",
  "GRANATARI",
  "INTERNO",
  "INTERRATO",
  "ISOLA",
  "LUNGADIGE",
  "LUNGARGINE",
  "LARGO",
  "LARGHETTO",
  "LUNGARNO",
  "LATO",
  "LUNGOBISAGNO",
  "LUNGODORA",
  "LIDO",
  "LISTA",
  "LITORANEA",
  "LUNGOMARE",
  "LOCALITA",
  "LOGGIA",
  "LOTTO",
  "LUNGOPARCO",
  "LUNGOPO",
  "LUNGOSTURA",
  "LUNGOTEVERE",
  "LUNGO",
  "MARITTIMA",
  "MARZARIA",
  "MASSERIA",
  "MERCATO",
  "MERCERIA",
  "MOLO",
  "MULATTIERA",
  "MURA",
  "MURAZZI",
  "PALAZZO",
  "PALUDO",
  "PANORAMICA",
  "PARADISO",
  "PARALLELA",
  "PARCO",
  "PASSEGGIO",
  "PASSEGGIATA",
  "PASSAGGIO",
  "PASSO",
  "PEDAMENTINA",
  "PENDICE",
  "PENNINATA",
  "PESCARIA",
  "PIAGGIA",
  "PIANO",
  "PIATTAFORMA",
  "PIAZZA",
  "PINETA",
  "PISCINA",
  "PODERE",
  "POGGIO",
  "POMPEO",
  "PONTE",
  "PONTILE",
  "PORTA",
  "PORTICO",
  "PORTICCIOLO",
  "PORTO",
  "PORTICHETTI",
  "PRATO",
  "PROLUNGAMENTO",
  "PROPRIETA",
  "PROVINCIALE",
  "PIAZZETTA",
  "PASSETTO",
  "PIAZZALE",
  "PIAZZOLA",
  "QUARTIERE",
  "QUADRATO",
  "QUADRIVIO",
  "RACCORDO",
  "RADA",
  "RAGGIO",
  "RAMO",
  "RAMPA",
  "RECINTO",
  "REGIONE",
  "RESIDENCE",
  "RESIDENZA",
  "RIALTO",
  "RIGASTE",
  "RIO",
  "RIONE",
  "RIPA",
  "RITIRO",
  "RIVA",
  "RIVIERA",
  "ROTONDA",
  "RIOTERA'",
  "RATTO",
  "RUGA",
  "RUGHETTA",
  "SACCA",
  "SALA",
  "SALITA",
  "SALIZZADA",
  "SBARCATOIO",
  "SCALA",
  "SCALEO",
  "SCALETTA",
  "SCALI",
  "SCALINATA",
  "SCALO",
  "SCALONE",
  "SDRUCCIOLO",
  "SECCO",
  "SELCIATO",
  "SENTIERO",
  "SESTIERE",
  "SITO",
  "SOBBORGO",
  "SOTTOPASSAGGIO",
  "SOTTOPORTICO",
  "SOTTO",
  "SOTTOVIA",
  "SPALTO",
  "SPIAGGIA",
  "SPIANATA",
  "STALIZZADA",
  "STANDS",
  "STAZIONE",
  "SOTTOMURA",
  "STRADA",
  "STRADALE",
  "STRETTOIA",
  "STRADELLA",
  "STRADELLO",
  "STRADONE",
  "SUPPORTICO",
  "SALITAVECCHIA",
  "SVINCOLO",
  "TANGENZIALE",
  "TENUTA",
  "TONDO",
  "TORRE",
  "TORRENTE",
  "TRASVERSALE",
  "TRATTO",
  "TRAVERSA",
  "TRESANDA",
  "TROI",
  "TRONCO",
  "VIA",
  "VALLE",
  "VALLONE",
  "VARCO",
  "VIA",
  "VIADOTTO",
  "VIALE",
  "VIALETTO",
  "VICINALE",
  "VICO",
  "VICOLO",
  "VILLAGGIO",
  "VILLA",
  "VILLETTA",
  "VILLINO",
  "VIOTTOLO",
  "VIUZZO",
  "VIANONIDENTIFICATA",
  "VO",
  "VOCABOLO",
  "VOLTA",
  "VOLTONE",
  "VICOLETTO",
  "ZIPA",
  "ZONA"
]
