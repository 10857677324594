//import React from "react";

export const footerMenuItems = [
  {
    //titolo: "About us",
    items: [
      { label: "Chi siamo", path: "/about-us" },
      { label: "Contatti", path: "/contatti" },
      { label: "Copyright", path: "/copyright" },
      { label: "Note Legali", path: "/note-legali" },
      { label: "Privacy", path: "/privacy" },
    ],
  },
  {
    //titolo: "Info",
    items: [
      { label: "tecnologia ftth", path: '/tecnologia' },
      { label: "Agevolazioni tariffarie", path: "/agevolazioni" },
      { label: "Trasparenza", path: "/trasparenza" },
      { label: "Trasparenza tariffaria", path: "/trasparenza-tariffaria" },
      { label: "Procedura Controversie", path: "/procedura-controversie" },
      //{ label: 'Rete', path: '/rete' },
    ],
  },
  {
    //titolo: "Supporto",
    items: [
      { label: "Supporto", path: "/assistenza" },
      // { label: "Mappa del sito", path: "/mappa-del-sito" }
    ],
  },
  // {
  //   titolo: "Social",
  //   items: [
  //     {
  //       label: "",
  //       path: "/",
  //       imgSrc: <i className="fab fa-facebook-square" />,
  //     },
  //     { label: "", path: "/", imgSrc: <i className="fab fa-instagram" /> },
  //   ],
  // },
];
