import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { auth } from "../../store/actionCreators/auth";
import Modale from '../../components/Modale/Modale';
import RecuperaPasswordModal from './RecuperaPassword/RecuperaPasswordModal';
import './Login.scss';

function Login({ auth }) {

  const user = sessionStorage.getItem('user');
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [pswd, setPswd] = useState('');
  const [showPswd, setShowPswd] = useState(false);
  const [hiddenModal, setHiddenModal] = useState(true)

  function login(e) {
    e.preventDefault();
    auth(username, pswd);
  }

  // L'utente loggato può essere reindirizzato ai seguenti path: 
  useEffect(() => {
    if (user && history.location.modificaMail) history.goBack(); // redirect alla pagina di modifica email se si è arrivati su login da quel path
    if (user && !JSON.parse(atob(user)).data.password_changed) history.push('/modifica-password'); // redirect alla modifica password se è il primo accesso
    if (user && JSON.parse(atob(user)).data.password_changed) history.push('/area-personale'); // redirect all'area personale dopo il primo accesso
  }, [user, history])

  return (
    <div className="row login-container">
      <div className='login-box col-lg-4 col-md-6 col-sm-10 col-xs-12'>
        <div className="p-4 mt-5 bg-linkem-red text-white border-radius-20 w-100">
          <h4 className="mb-5">Entra nell'Area Clienti JustSpeed</h4>
          <form onSubmit={login}>
            <div className="form-group">
              <label htmlFor="Email">E-mail</label>
              <input type="email" className="form-control" id="Email" value={username} onChange={e => setUsername(e.target.value)} />
            </div>

            <div className="form-group">
              <label htmlFor="Password">Password</label>
              <div className="input-group">
                <input type={showPswd ? "text" : "password"} className="form-control" id="Password" value={pswd} onChange={e => setPswd(e.target.value)} />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className={`fas cursor-pointer ${ showPswd ? "fa-eye" : "fa-eye-slash" }`} onClick={() => setShowPswd(!showPswd)} />
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-linkem-blue btn-sm border-radius-20 pl-4 pr-4 mt-3">Login</button>
            </div>

            <div className="d-flex justify-content-end">
              <p className="pswd-dimenticata my-2" onClick={() => setHiddenModal(false)}>
                <strong>Hai dimenticato la password?</strong>
              </p>
            </div>
          </form>
        </div>
      </div>

      <Modale
        hidden={hiddenModal}
        displayDismissButton={true}
        bgColor='bg-linkem-blue'
        dismissButtonClass='absolute-close-button'
        setToggleModale={() => setHiddenModal(true)}>
        <RecuperaPasswordModal />
      </Modale>
    </div >
  );
}

const mapDispatchToProps = dispatch => ({
  auth: (username, password) => dispatch(auth(username, password))
});

export default connect(null, mapDispatchToProps)(Login);
