import { SET_ACTIVATION_TYPE, UPDATE_CURRENT_STEP, SAVE_DATI_CONTATTO, UPDATE_DATI_CONFIGURAZIONE, SAVE_DATI_CONFIGURAZIONE, SAVE_DATI_CGC, UPDATE_DATI_CONTATTO, RESET_DATI_WORKFLOW } from '../actions/activationSteps';
import { activationStepsLabels } from '../constants/activationSteps';
import { enumContractTypes, enumContractState } from '../../pages/Sottoscrizione/constants';

// ------ TEMPLATE DATI DELLE RELATIVE FORM ------

// Form dati di contatto
const templateDatiContatto = {
  customer: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '', 
    home_phone_number: ''
  },
  lead_agreement: false,
  active_line: false,
  coverage: {},

}

// Form dati di configurazione
const templateDatiConfigurazione = {
  fiscal_code: '',
  gender: 'not_specified',
  document: { number: '', type: 'ci', issued_at: '', entity: '', nationality: 'ITALIA' },
  birthData: { born_at: '', born_city: '', born_province: '', born_country: 'ITALIA' },
  activation_address: { intercom_name: '', city: '', province: '', postal_code: '', street_name: '', address_number: '' , stair: '', nui: '', floor: ''},
  billing_address: { intercom_name: '', city: '', province: '', postal_code: '', street_name: '', address_number: '' },
  referent: { first_name: '', last_name: '', phone_number: '', home_phone_number: '' },
  checkAttivazioneFatturazione: false
}

// Form dati per CGC
const templateDatiCgc = {
  scrolledAll: false,
  cgc_agreement: false,
  contract_agreement: false,
  commercial_agreement: false
}

// Initial State oggetto con i dati
const datiStepper = {
  datiContatto: templateDatiContatto,
  datiConfigurazione: templateDatiConfigurazione,
  datiCgc: templateDatiCgc
}
// ------ END TEMPLATE DATI DELLE RELATIVE FORM ------

// Path dello stepper in base al tipo di workflow

// FUNNEL
const componentMappingFunnel = [
  { step: 1, labelStepper: activationStepsLabels.DATI_ANAGRAFICI, currentlyActive: true, path: '/dati-contatto' },
  { step: 2, labelStepper: activationStepsLabels.CONFIGURA, currentlyActive: false, path: '/dati-contrattuali' },
  { step: 3, labelStepper: activationStepsLabels.ATTIVAZIONE, currentlyActive: false, path: '/verifica-otp/:token' },
  { step: 4, labelStepper: activationStepsLabels.PAGAMENTI, currentlyActive: false, path: '/dati-pagamento' },
  { step: 5, labelStepper: activationStepsLabels.CGC, currentlyActive: false, path: '/accettazione-cgc' }
]

// FUNNEL VAS
const componentMappingFunnelVas = [
  { step: 1, labelStepper: activationStepsLabels.SELEZIONE_VAS, currentlyActive: true, path: '/selezione-vas' },
  { step: 2, labelStepper: activationStepsLabels.DATI_ANAGRAFICI, currentlyActive: false, path: '/dati-contatto' },
  { step: 3, labelStepper: activationStepsLabels.CONFIGURA, currentlyActive: false, path: '/dati-contrattuali' },
  { step: 4, labelStepper: activationStepsLabels.ATTIVAZIONE, currentlyActive: false, path: '/verifica-otp/:token' },
  { step: 5, labelStepper: activationStepsLabels.PAGAMENTI, currentlyActive: false, path: '/dati-pagamento' },
  { step: 6, labelStepper: activationStepsLabels.CGC, currentlyActive: false, path: '/accettazione-cgc' }
]

// DIGITAL
const componentMappingDigital = [
  { step: 1, labelStepper: activationStepsLabels.ATTIVAZIONE, currentlyActive: true, path: '/verifica-otp/:token' },
  { step: 2, labelStepper: activationStepsLabels.PAGAMENTI, currentlyActive: false, path: '/dati-pagamento' },
  { step: 3, labelStepper: activationStepsLabels.CGC, currentlyActive: false, path: '/accettazione-cgc' }
]

// UTENTE FUNNEL O DIGITAL CHE SI E' FERMATO ALLE CGC
const componentMappingPaymentSubmitted = [
  { step: 1, labelStepper: activationStepsLabels.ATTIVAZIONE, currentlyActive: true, path: '/verifica-otp/:token' },
  { step: 2, labelStepper: activationStepsLabels.CGC, currentlyActive: false, path: '/accettazione-cgc' }
]

// PAPER
const componentMappingPaper = [
  { step: 1, labelStepper: activationStepsLabels.ATTIVAZIONE, currentlyActive: true, path: '/verifica-otp/paper/:token' }
]

// Temporary DA ELIMINARE
const componentMappingTemporary = [
  { step: 1, labelStepper: activationStepsLabels.ATTIVAZIONE, currentlyActive: true, path: '/verifica-otp/:token/:temp' },
  { step: 2, labelStepper: activationStepsLabels.PAGAMENTI, currentlyActive: false, path: '/dati-pagamento' },
  { step: 3, labelStepper: activationStepsLabels.CGC, currentlyActive: false, path: '/accettazione-cgc' }
]

// Reducer per gestire il workflow tra i vari componenti dello stepper
export const activationSteps = (state = componentMappingFunnel, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_STEP:
      return state.map(el => el.step === action.step ? ({ ...el, currentlyActive: true }) : ({ ...el, currentlyActive: false }));
    case SET_ACTIVATION_TYPE:
      if(action.activationState === enumContractState.PAYMENT_SUBMITTED) {
        return componentMappingPaymentSubmitted
      }
      else if(action.activationType === enumContractTypes.digital) {
        return componentMappingDigital
      }
      else if (action.activationType === enumContractTypes.paper) {
        return componentMappingPaper
      }
      else if (action.activationType === enumContractTypes.temporary) {
        return componentMappingTemporary
      }
      else if (action.activationType === enumContractTypes.funnelVas) {
        return componentMappingFunnelVas
      }
      else {
        return componentMappingFunnel
      };
    default:
      return state;
  }
}

export const datiWorkflow = (state = datiStepper, action) => {
  switch (action.type) {
    case SAVE_DATI_CONTATTO:
      return { ...state, datiContatto: action.datiContatto };
    case UPDATE_DATI_CONTATTO:
      return { ...state, datiContatto: { ...state.datiContatto, [action.objKey]: action.value } };
    case SAVE_DATI_CONFIGURAZIONE:
      return { ...state, datiConfigurazione: action.datiConfigurazione };
    case UPDATE_DATI_CONFIGURAZIONE:
      return { ...state, datiConfigurazione: { ...state.datiConfigurazione, [action.objKey]: action.value } };
    case SAVE_DATI_CGC:
      return { ...state, datiCgc: action.datiCgc };
    case RESET_DATI_WORKFLOW:
      return datiStepper;
    default:
      return state;
  }
}


/* 
RESPONSE DOPO STEP 1

active_line: false
coverage: {
  actual_download_speed: 1000
  actual_upload_speed: 300
  address_number: "47"
  city: "ROMA"
  connection_type_id: 1
  created_at: "2020-11-03T10:15:09.000000Z"
  download_speed: 1000
  egon_number: "380100011517229"
  id: 13
  kit_id: "FWS9020119/b060/1/62"
  province: "RM"
  street_name: "SAN QUINTINO"
  toponymic_particle: "VIA"
  updated_at: "2020-11-03T10:15:09.000000Z"
  upload_speed: 300
}
coverage_id: 13
created_at: "2020-11-03T10:15:09.000000Z"
customer: {
  born_at: null
  born_city: null
  created_at: "2020-11-03T10:15:09.000000Z"
  creator_id: null
  creator_type: null
  customer_code: null
  document_id: null
  email: "barakarob@gmail.com"
  email_verified_at: null
  first_name: "Robert"
  fiscal_code: null
  gender: null
  id: 13
  last_name: "Barakat"
  lead_id: 13
  linkem_customer_code: null
  phone_number: "0699675194"
  phone_number_verified_at: null
  state: "creating"
  updated_at: "2020-11-03T10:15:09.000000Z"
}
customer_id: 13
id: 13 => è il contratto
lead_agreement_at: "2020-11-03T10:15:09.000000Z"
state: "created"
type: "funnel"
updated_at: "2020-11-03T10:15:09.000000Z"

*/