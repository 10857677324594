import axiosInstance from "../../api/interceptor";
import {toggleSpinner, toggleToast} from "./general";

import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT
} from '../actions/auth';

export const authStart = () => {
    return {
        type: AUTH_START,
        isLoading: true
    };
};

/**
 *
 * @param user
 * @returns {{type: string, error: null, user: *}}
 */
export const authSuccess = (user) => {
    return {
        type: AUTH_SUCCESS,
        user,
        error: null,
        isLoading: false
    };
};

export const authFail = (error) => {
    return {
        type: AUTH_FAIL,
        error,
        isLoading: false
    };
};

export const logout = () => {
    localStorage.removeItem('expiration');
    sessionStorage.removeItem('user');
    return {
        type: AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

// TODO: da cancellare quando ci saranno le chiamate BE
/*export const auth = user => {
    return dispatch => {
        dispatch(authStart());
        dispatch(toggleSpinner());
        const oreExpiration = 2;
        const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
        const user = {
            "data": {
                "id": 3,
                "name": "Utente Prova",
                "email": "maprea@keybiz.it",
                "email_verified_at": null,
                "level": "user",
                "role_object_type": null,
                "role_object_id": null,
                "active": 1,
                "created_at": "2020-10-24T15:23:07.000000Z",
                "updated_at": "2020-10-24T15:23:07.000000Z"
            }
        }
        console.log(btoa(JSON.stringify(user)))
        sessionStorage.setItem('user', btoa(JSON.stringify(user)));
        sessionStorage.setItem('expiration', expirationDate);
        sessionStorage.setItem('token', 'token');
        dispatch(toggleSpinner());
        dispatch(authSuccess(user));
    }
};*/

// TODO: da tenere commentata finché non ci saranno le chiamate BE
export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        dispatch(toggleSpinner());

        axiosInstance.post('/login', {
            email,
            password
        }, {'Content-Type': 'multipart/form-data'})
            .then(({data}) => {
                const oreExpiration = 2;
                const expirationDate = new Date(new Date().getTime() + oreExpiration * 60 * 60 * 1000);
                localStorage.setItem('expiration', expirationDate);
                sessionStorage.setItem('user', btoa(JSON.stringify(data)));
                dispatch(toggleSpinner());
                dispatch(authSuccess(data));
            })
            .catch(e => {
                const {messaggio} = e;
                dispatch(authFail(messaggio));
                dispatch(toggleSpinner());
                /*
                 * toggleToast: mostra un toast con un determinato messaggio.
                 * Per veder come funziona guardate la funzione toggleToast prensente in questo file src/store/actionCreators/general.js
                 * @param tipo: @string definisce il tipo di toast da lanciare: 'e' = errore => toast rosso, 's' = successo => toast verde
                 * @param messaggio: @string definisce il messaggio mostrato nel toast
                 */
                dispatch(toggleToast('e', 'E-mail o Password errate'));
            });
    };
};

export const authCheckState = () => {
    authStart();

    return dispatch => {
        const expirationDate = new Date(localStorage.getItem('expiration'));
        if (expirationDate.getTime() <= new Date().getTime()) {
            dispatch(logout());
        } else {
            // const user = atob(sessionStorage.getItem('user'));
            // dispatch(authSuccess(JSON.parse(user)));
            dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
        }
        // const token = sessionStorage.getItem('token');
        // if (!token) {
        //     dispatch(logout());
        // } else {
        //     const expirationDate = new Date(sessionStorage.getItem('expiration'));
        //     if (expirationDate.getTime() <= new Date().getTime()) {
        //         dispatch(logout());
        //     } else {
        //         const user = sessionStorage.getItem('user');
        //         dispatch(authSuccess(JSON.parse(user)));
        //         dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
        //     }
        // }
    };
};
