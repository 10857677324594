import React, { useCallback, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { getComuniNexev } from '../../../api/comuniEcap/api';
import { createLead, FETCH_TYPES } from '../../../api/leads/api';
import { toggleSpinner, toggleToast } from '../../../store/actionCreators/general';
import ButtonLinkem from '../../ButtonLinkem/ButtonLinkem';
import { checkCellNum, regexp, ValidateEmail } from '../../utils/utils';
import BootstrapSpinner from '../../BootstrapSpinner/BootstrapSpinner';
import Autocomplete from '../../Autocomplete/Autocomplete';

function ContactForm({ coverage, activeLine, isEsitoVerifica, isModale, bgColor, textColor, title, subtitle }) {

  // Recupero il body dell'esito KO dal reducer che gestisce anche apertura/chiusura barra esito ko
  const coverageKO = useSelector(({ esitoNegativoReducer }) => esitoNegativoReducer.coverage);
  const { privacy_url, campaign } = useSelector(state => state);

  const reduxDispatch = useDispatch();

  const history = useHistory();

  /**
   * Initial form state
   * 
   * value: il valore del campo
   * valid: la validità del campo
   * validate: funzione validatrice
   */
  const [form, setForm] = useState({
    comune: { value: '', valid: false, validate: value => value && value.length > 0 },
    nome: { value: '', valid: false, validate: value => value && value.length > 0 },
    cognome: { value: '', valid: false, validate: value => value && value.length > 0 },
    telefono: { value: '', valid: false, validate: value => value && checkCellNum(value) },
    email: { value: '', valid: false, validate: value => value && ValidateEmail(value) },
  });
  const { comune, nome, cognome, telefono, email } = form;

  const [privacy_accepted, setPrivacy_accepted] = useState([
    { label: 'accetto', value: '1', checked: true },
    { label: 'non accetto', value: '0', checked: false }
  ]);
  const [richestaEffettuata, setRichestaEffettuata] = useState(false);
  // States per validazione di ulteriori campi
  const [checkPrivacy, setCheckPrivacy] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [comuniOptions, setComuniOptions] = useState([]);

  const fetchComuni = (search) => {
    setSpinnerComuneOn(true);
    getComuniNexev(search)
      .then(res => {
        setComuniOptions(res.data.data);
        setSpinnerComuneOn(false);
        setAutoCompleteComuneOpen(true);
      })
      .catch(e => {
        reduxDispatch(toggleToast('e', e.messaggio));
        setSpinnerComuneOn(false);
      })
  };

  const debouncedComuni = useCallback(debounce(value => {
    fetchComuni(value);
    // eslint-disable-next-line
  }, 800), []);

  // onChange generico
  const changeFormField = ({ target: { name, value } }) => {
    const { validate } = form[name];
    setForm({
      ...form,
      [name]: { // Accedo alla porzione di oggetto form
        validate, // Ritorno il campo validate che altrimenti andrebbe perso
        value, // Setto il valore
        valid: validate(value) // Effettuo la validazione del campo
      }
    })
    if (name === 'comune') {
      if (value.length > 2) { // Se la stringa non è vuota effettuo la ricerca comuni
        debouncedComuni(value);
      } else {         // Se ho svuotato l'input resetto le options
        setComuniOptions([]);
      }
    }
  };

  /**
   * Questo blocco ha lo scopo di verificare
   * che il campo comune sia scritto nel modo corretto
   */
  const validateComune = () => {
    const find = comuniOptions.find((c) => c.COMUNE.toUpperCase() === comune.value.toUpperCase());
    setForm({
      ...form, comune: {
        ...comune, valid: find !== undefined
      }
    })
  };

  // eslint-disable-next-line
  useEffect(() => { validateComune() }, [comuniOptions, comune.value])
  /* Fine validazione comune */

  // Metodo per assicurarsi che il campo "Cellulare" abbia esclusivamente caratteri numerici
  function handlePhoneChange(e) {
    if (regexp.test(e.target.value) || e.target.value === "") {
      setForm({
        ...form,
        telefono: {
          value: e.target.value,
          valid: telefono.validate(e.target.value),
          validate: telefono.validate
        }
      })
    }
  }

  function handleRadioButtons(id) {
    const temp = privacy_accepted.map((el, idx) => idx === id ? { ...el, checked: true } : { ...el, checked: false })
    setPrivacy_accepted(temp);
  }

  // Metodo per inviare la richiesta di richiamo
  async function chiediRichiamo(body, tipoCall) {
    reduxDispatch(toggleSpinner())
    createLead(tipoCall, body).then(() => {
      // toggleToast('s', 'Abbiamo ricevuto la sua richiesta. Grazie per averci contattato!')
      setRichestaEffettuata(true);
      reduxDispatch(toggleSpinner());
    })
      .catch(e => {
        reduxDispatch(toggleToast('e', e.messaggio));
        reduxDispatch(toggleSpinner());
      })
  }

  function handleSubmit() {
    // in base allo status di esito verifica specifico il tipo di chiamata che verrà eseguita
    const typeOfCall = (() => {
      if (coverage && activeLine) return FETCH_TYPES.activeLine;
      if (coverage) return FETCH_TYPES.coverageOk;
      if (coverageKO) return FETCH_TYPES.coverageKo;
      else return FETCH_TYPES.callMe;
    })();
    // in base allo status di esito verifica specifico il tipo di chiamata che verrà eseguita
    const typeOfCallGaLabels = (path) => {
      if (coverage && activeLine) return 'Richiamami per linea già attiva';
      if (coverage) return 'Ricontattami Operatore Coverage OK';
      if (coverageKO) return 'Ricontattami Operatore Coverage KO';
      else return `Ricontattami Modale ${path}`
    }
    // Creo il body con i dati della form e di eventuali campagne utm
    let body = {
      first_name: nome.value,
      last_name: cognome.value,
      phone_number: telefono.value,
      email: email.value,
      privacy_accepted: '1',
      utm_source: campaign?.utm_source ? campaign.utm_source : '',
      utm_medium: campaign?.utm_medium ? campaign.utm_medium : '',
      utm_campaign: campaign?.utm_campaign ? campaign.utm_campaign : '',
      utm_term: campaign?.utm_term ? campaign.utm_term : '',
      utm_content: campaign?.utm_content ? campaign.utm_content : ''
    };
    //Controllo se ho necessità di validare il comune altrimenti lo dò per valido
    const isValidComune = typeOfCall === FETCH_TYPES.callMe ? comune.valid : true;
    // Se ho gli esiti di verifica copertura, integro il body con i dati della copertura
    if (coverage) body = {
      ...body, coverage
    };
    if (coverageKO) body = {
      ...body, coverage: { ...coverageKO }
    };
    // Se la fetch è "call-me" aggiungo l'attributo city
    if (typeOfCall === FETCH_TYPES.callMe) body = {
      ...body, city: comune.value
    }
    if (!wasSubmitted) setWasSubmitted(true);
    // Esegue la chiamata al BE solo se i campi sono validi 
    if (
      isValidComune && nome.valid && cognome.valid &&
      email.valid && telefono.valid && checkPrivacy
    ) {
      // Effettuo la fetch
      chiediRichiamo(body, typeOfCall);

      ReactGA.event({
        category: 'Form',
        action: typeOfCallGaLabels(history.location.pathname),
        label: history.location.pathname
      });
      ReactPixel.track(
        'Lead',
        {
          content_name: typeOfCallGaLabels(history.location.pathname),
          content_category: 'Form'
        }
      );
    }
  };

  /**
   * @returns boolean formIsValid, risultato della validazione dei campi del form.
   * Returns true se il form non ha campi.
   */
  const validateAllFormFields = () => {
    let formIsValid = true;
    for (const [key, obj] of Object.entries(form)) {
      const { value, validate } = obj;
      setForm({
        ...form,
        [key]: { ...obj, valid: validate(value) }
      });
      formIsValid = validate(value)
    }
    const privacyAccepted = privacy_accepted.filter(el => el.value = "1")[0].checked;
    setCheckPrivacy(privacyAccepted);
    return formIsValid && privacyAccepted;
  }

  useEffect(() => {
    validateAllFormFields();
    // eslint-disable-next-line
  }, [nome.value, cognome.value, email.value, telefono.value, privacy_accepted]);

  // Grazie a questa costante riusciamo a determinare se mostrare o meno l'input 'city'
  // il quale è necessario per fornire la città di appartenenza al CRM tramite API '/leads/create'
  const showCityInput = !coverage && !coverageKO && !activeLine && !isEsitoVerifica;

  // BLOCCO COMUNE START (Copia verifica copertura)

  const [autocompleteComuneStyle, setAutoCompleteComuneStyle] = useState({});
  const [autoCompleteComuneOpen, setAutoCompleteComuneOpen] = useState(false);
  const [spinnerComuneOn, setSpinnerComuneOn] = useState(false);

  // Impedisce l'uso del tasto tab per spostarsi su un altro input
  function preventTab(e) {
    if (e.keyCode === 9) e.preventDefault()
  };

  const inputComune = React.useRef();
  const divComune = React.useRef();

  const handleCloseComune = () => {
    setForm({
      ...form,
      comune: { ...comune, value: '' }
    });
    setAutoCompleteComuneOpen(false);
  };

  function handleChiusuraDiv(nomeCampo) {
    if (nomeCampo === 'comune') handleCloseComune();
  };

  const noResult = (nomeCampo = 'comune') => <li onClick={() => handleChiusuraDiv(nomeCampo)}>
    La ricerca non ha prodotto risultati
  </li>;

  function handleClickOutsideList(e) {
    if (
      divComune?.current && inputComune?.current &&
      !divComune?.current.contains(e.target) &&
      !inputComune?.current.contains(e.target) &&
      (divComune?.current.firstChild.clientHeight !== 0)) {
      handleCloseComune();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideList);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!comune.value || comune.value.length < 3) {
      setComuniOptions([]);
      setAutoCompleteComuneOpen(false);
    }
  }, []); // eslint-disable-line

  const onClickAutocompleteComune = (el) => {
    setForm({
      ...form,
      comune: { ...comune, value: el.COMUNE }
    });
    setAutoCompleteComuneOpen(false);
  }

  const onFocusComune = () => {
    let style = {
      width: inputComune.current.getBoundingClientRect().width - 31,
      left: 18,
    }
    // Apriamo la select sopra/sotto l'input, in base alla posizione dello stesso
    style = inputComune.current.getBoundingClientRect().top <
      inputComune.current.getBoundingClientRect().bottom ?
      { ...style, top: inputComune.current.getBoundingClientRect().height + 1 } :
      { ...style, bottom: inputComune.current.getBoundingClientRect().height + 1 };
    setAutoCompleteComuneStyle(style);
  };

  // BLOCCO COMUNE END

  return (
    <>
      {
        richestaEffettuata
          ? <div className={`my-3 text-center ${textColor ? textColor : 'text-white'}`}>
            <p>
              Abbiamo ricevuto correttamente la tua richiesta.
              <br />
              Un nostro operatore ti ricontatterà al più presto dal numero
              <br />
              <strong>02 89 890</strong>
            </p>
          </div>
          : <div className={`form-container ${bgColor ? bgColor : 'bg-bianco'} ${textColor ? textColor : ''}`}>
            <h4 className="color-linkem-red responsive-title">
              {coverageKO
                ? 'Ci dispiace! L’offerta JustSpeed non è attualmente disponibile presso l’indirizzo inserito.'
                : (title ? title : 'Lasciaci i tuoi dati')
              }
            </h4>
            <p className="responsive-subtitle">
              {coverageKO && 'Compila il modulo sottostante per essere ricontattato non appena il tuo indirizzo sarà raggiunto dal nostro servizio'}
              {subtitle}
            </p>
            <div className={`form-group ${isModale ? 'mt-2' : 'mt-3'}`}>

              <div className="row m-0">

                {/* COMUNE */}
                {
                  showCityInput && (
                    <div className={`mb-2 ${isEsitoVerifica ? 'col-6 p-0' : 'col-12 p-0'}`}>
                      <input
                        type="text"
                        ref={inputComune}
                        className="form-control p-3 border-radius-20"
                        name="comune"
                        onKeyDown={preventTab}
                        onChange={changeFormField}
                        value={comune.value}
                        placeholder="Comune"
                        onFocus={onFocusComune}
                      />
                      <BootstrapSpinner extraClass={classNames("input-field-spinner", { 'd-none': !spinnerComuneOn })} />
                      <span ref={divComune}>
                        <Autocomplete closed={!autoCompleteComuneOpen} style={autocompleteComuneStyle}>
                          {!comuniOptions ? noResult('comune') :
                            React.Children.toArray(comuniOptions.map(el =>
                              <li onClick={() => onClickAutocompleteComune(el)}>
                                {el.COMUNE}
                              </li>
                            ))
                          }
                        </Autocomplete>
                      </span>
                      <div className={classNames("small text-danger ml-3", { 'd-none': (comune.valid && wasSubmitted) || !wasSubmitted })}>
                        Campo obbligatorio
                      </div>
                    </div>
                  )
                }

                {/* NOME */}
                <div className={`mb-2 ${isEsitoVerifica ? 'col-6 p-0' : 'col-12 p-0'}`}>
                  <input
                    name="nome"
                    type="text"
                    className={classNames("form-control p-3 border-radius-20", { "form-control-sm": isModale, 'mr-1': isEsitoVerifica, "is-invalid": !nome.valid && wasSubmitted })}
                    value={nome.value}
                    onChange={changeFormField}
                    placeholder="Nome"
                  />
                  <div className={classNames("small text-danger ml-3", { 'd-none': (nome.valid && wasSubmitted) || !wasSubmitted })}>
                    Campo obbligatorio
                  </div>
                </div>

                {/* COGNOME */}
                <div className={`mb-2 ${isEsitoVerifica ? 'col-6 p-0' : 'col-12 p-0'}`}>
                  <input
                    name="cognome"
                    type="text"
                    className={classNames("form-control p-3 border-radius-20", { "form-control-sm": isModale, 'ml-1': isEsitoVerifica, "is-invalid": !cognome.valid && wasSubmitted })}
                    value={cognome.value}
                    onChange={changeFormField}
                    placeholder="Cognome"
                  />
                  <div className={classNames("small text-danger ml-3", { 'd-none': (cognome.valid && wasSubmitted) || !wasSubmitted })}>
                    Campo obbligatorio
                  </div>
                </div>

                {/* EMAIL */}
                <div className={`col-md-6 col-sm-12 p-0 mb-2`}>
                  <input
                    name="email"
                    type="email"
                    className={classNames("form-control mr-md-1 p-3 border-radius-20", { "form-control-sm": isModale, "is-invalid": !email.valid && wasSubmitted })}
                    value={email.value}
                    onChange={changeFormField}
                    placeholder="E-mail"
                  />
                  <div className={classNames("small text-danger ml-3", { 'd-none': (email.valid && wasSubmitted) || !wasSubmitted })}>
                    Indirizzo e-mail non valido
                  </div>
                </div>

                {/* CELLULARE */}
                <div className="col-md-6 col-sm-12 p-0 mb-0">
                  <input
                    name="telefono"
                    type="text"
                    className={classNames("form-control ml-md-1 mb-0 p-3 border-radius-20", { "form-control-sm": isModale, "is-invalid": !telefono.valid && wasSubmitted })}
                    value={telefono.value}
                    onChange={handlePhoneChange}
                    placeholder="Cellulare"
                    maxLength={12}
                  />
                  <div className={classNames("small text-danger ml-3", { 'd-none': (telefono.valid && wasSubmitted) || !wasSubmitted })}>
                    Verifica la correttezza del dato
                  </div>
                </div>

                <div className="col-12 mt-0 px-0">
                  <p className="text-privacy">
                    Cliccando accetto, dichiaro di aver preso visione dell'
                    <a
                      href={process.env.REACT_APP_BASENAME_STRAPI + (coverageKO ? privacy_url.privacyLeadTwelveMonths : privacy_url.privacyLeadOneMonth)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      informativa
                    </a>
                    qui riportata e richiedo di essere ricontattato
                  </p>
                </div>

                <div className="col-12 my-2 px-0 d-flex justify-content-end">
                  {
                    privacy_accepted.map((el, idx) => (
                      <div key={idx} className={`form-check ${idx === 0 ? 'col-lg-3 col-md-4 col-sm-4' : 'col-lg-4 col-md-5 col-sm-4'} col-xl-3 col-xs-6 pr-0 text-right`}>
                        <input
                          className="form-check-input"
                          type="radio"
                          value={el.value}
                          checked={el.checked}
                          onChange={() => handleRadioButtons(idx)}
                        />
                        <label className="contact-form-check-label">
                          {el.label}
                        </label>
                      </div>
                    ))
                  }
                </div>

                <div className={classNames("small text-danger ml-3 w-100 text-center", { 'd-none': (checkPrivacy && wasSubmitted) || !wasSubmitted })}>
                  L'accettazione della privacy è obbligatoria
                </div>

                <ButtonLinkem
                  linkemRed={!bgColor || bgColor === "bg-bianco"}
                  polarizedRed={bgColor}
                  className="btn-ricontatto border-radius-30 w-100 mt-2 py-2 letter-spacing-3"
                  onClick={handleSubmit}>
                  <i className="fas fa-phone-volume mr-3" />
                  <span className="pt-1 termina-demi">
                    Voglio essere ricontattato
                  </span>
                </ButtonLinkem>
              </div>

            </div>
          </div>
      }

    </>
  )
}

export default ContactForm;
