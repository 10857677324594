export const enumContractTypes = {
  funnelVas: 'funnelVas',
  funnel: 'funnel',
  digital: 'digital',
  paper: 'on_paper',
  temporary: 'temporary' // TEMPORARY DA ELIMINARE 
}

export const enumContractState = {
  PAYMENT_SUBMITTED: 'payment_submitted',
  OTP_CONFIRMED: 'otp_confirmed'
}

export const enumLanding = {
  STEP_2: 'STEP_2',
  SOTTOSCRIZIONE_PAPER: 'SOTTOSCRIZIONE_PAPER',
  SOTTOSCRIZIONE_RIUSCITA: 'SOTTOSCRIZIONE_RIUSCITA'
}