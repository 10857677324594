import React from 'react';
import classNames from 'classnames';
import './Autocomplete.scss';

function Autocomplete({children, closed, style}) {
  return (
    <div className={classNames("data-container", { 'd-none': closed })} style={style}>
      <ul className="mb-0">
        {children}
      </ul>
    </div>
  )
}

export default Autocomplete
