import {SAVE_PRIVACY_URL, SAVE_PRIVACY_LEAD1_URL, SAVE_PRIVACY_LEAD12_URL} from '../actions/privacy';

export const getPrivacyUrl = url => ({
  type: SAVE_PRIVACY_URL,
  url
}) 

export const getPrivacyLeadOneUrl = url => ({
  type: SAVE_PRIVACY_LEAD1_URL,
  url
}) 

export const getPrivacyLeadTwelveUrl = url => ({
  type: SAVE_PRIVACY_LEAD12_URL,
  url
}) 