import axios from "axios";

const { REACT_APP_BASENAME_STRAPI } = process.env;
const TIMEOUT = 60 * 1000;

const axiosStrapiInstance = axios.create({
  timeout: TIMEOUT,
  baseURL: REACT_APP_BASENAME_STRAPI,
});

axiosStrapiInstance.interceptors.request.use(
  (config) => {
    // const token = sessionStorage.getItem('token');
    // if (token) {
    //     config.headers['Authorization'] = 'Basic ' + token;
    // }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const errorHandler = (error) => {
  let messaggio = "Errore generico!";

  if (error.response) {
    const {
      status,
      data: { message },
    } = error.response;

    switch (status) {
      case 401: {
        messaggio = "Non sei autorizzato. Controlla le tue credenziali";
        break;
      }
      default:
        messaggio = message ? message : "Errore generico!";
    }
  }

  return Promise.reject({ messaggio });
};

const successHandler = (response) => {
  return response;
};

axiosStrapiInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosStrapiInstance;
