import React from "react";
import { useHistory } from "react-router-dom";
import { footerMenuItems } from "./constants/footerMenuItems";
import footerIcon from "../../assets/img/logoFooter2022.png";
import classNames from 'classnames';
import "./Footer.scss";

function Footer() {
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  const [footerPb, setFooterPb] = React.useState(true)

  // Se siamo sulla homepage rialziamo il footer per evitare che sia coperto da verifica copertura
  React.useEffect(() => {
    history.listen((location) => {
      if (location.pathname === '/') {
        setFooterPb(true)
      } else setFooterPb(false)
    })
  }, [history])

  return (
    <div className="footer-container bg-linkem-red">
      <div className={classNames("footer-wrapper", { 'footer-wrapper-pb': footerPb })}>
        {
          footerMenuItems.map((el, idx) => (
            <div key={idx} className={classNames("footer-item-container", { 'last': idx === footerMenuItems.length - 1 })}>
              <div className="footer-text-container">
                {/* <h4 className="text-uppercase jeko-extrabold-font">{el.titolo}</h4> */}
                <ul>
                  {React.Children.toArray(
                    el.items.map((item) => (
                      <li
                        className="footer-item runda-normal text-uppercase"
                        onClick={() => history.push(item.path)}
                      >
                        {item.imgSrc ? item.imgSrc : item.label}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          ))
        }

        <div className="logo-container">
          <img className="icon-footer" src={footerIcon} alt="" />
        </div>

        <div className="small runda-normal w-100 text-center text-white" style={{ marginTop: '2rem', marginBottom: '1.5rem' }}>
          © {currentYear} Tiscali Italia S.p.A  Sede Legale Località Sa Illetta km 2.300, 09123 Cagliari, Italia | Tel. +39 070 4601 1 <br/>
          Cap. Soc. Euro 36.994.000 i.v. | P.IVA 02508100928 | R.E.A. 204250 | C.C.I.A.A. Cagliari
        </div>
      </div>
    </div>
  );
}

export default Footer;
