import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateCurrentStep } from '../../store/actionCreators/activationSteps';
import { activationStepsLabels } from '../../store/constants/activationSteps';
import { toggleToast, toggleSpinner } from '../../store/actionCreators/general';
import { setSelectedOffer } from '../../store/actionCreators/commercialOffers';
import Stepper from '../../components/Stepper/Stepper';
import RiepilogoOfferta from './RiepilogoOfferta/RiepilogoOfferta';
import './Sottoscrizione.scss';

// Array di oggetti contenenti tutte info per ogni sub-route quali n° stepper, relativo componente, titolo pagina

function Sottoscrizione({ activationSteps, updateCurrentStep, selectedOffer, setSelectedOffer, datiWorkflow, campaign, toggleToast, toggleSpinner, children }) {

  const history = useHistory();

  // Genero il componente da visualizzare in base al current step
  // function currentComponent() {
  //   const temp = activationSteps.filter(el => el.currentlyActive)[0];
  //   const Component = temp.componente;
  //   return <Component titolo={temp.titolo}
  //     setActiveStepper={setActiveStepper}
  //     activeStepper={activeStepper}
  //     activationSteps={activationSteps} />
  // }

  // Metodo per andare avanti di uno step
  function stepForward() {
    const currentStep = activationSteps.filter(el => el.currentlyActive)[0].step;
    history.push(activationSteps.filter(el => el.step === currentStep+1)[0].path);
    updateCurrentStep(currentStep + 1);
  }

  // Metodo per andare indietro di uno step
  function stepBackward() {
    const currentStep = activationSteps.filter(el => el.currentlyActive)[0].step;
    history.push(activationSteps.filter(el => el.step === currentStep-1)[0].path);
    updateCurrentStep(currentStep - 1);
  }

  // Metodo per verificare che il path sul quale siamo ha la var currentlyActive === true
  function checkStep(stepLabel) {
    const currentStep = activationSteps.filter(el => el.labelStepper === stepLabel)[0].step;
    const isStepActive = activationSteps.filter(el => el.labelStepper === stepLabel)[0].currentlyActive;
    if(!isStepActive) updateCurrentStep(currentStep);
  }

  // Metodo per passare delle props a tutti i children
  const childrenWithProps = React.Children.map(children, (child, index) => React.cloneElement(child, { activationSteps, activationStepsLabels, checkStep, datiWorkflow, selectedOffer, setSelectedOffer, campaign, toggleToast, toggleSpinner, stepForward, stepBackward }));

  return (
      <div className="container-fluid static-page-container bg-transparent">
        <div className="row pt-3 pb-5">

          <div className="col-md-6 offset-md-1 col-sm-12 mt-5 pl-4">
            <Stepper data={activationSteps} />
          </div>

          <div className="row adaptable-padding justify-content-between align-items-start w-100 m-0">
            <div className="col-md-6 offset-md-1 col-sm-12 mb-3">
              {childrenWithProps}
            </div>

            <div className="col-md-5 col-sm-12 d-flex justify-content-center">
              <RiepilogoOfferta inStepper />
            </div>
          </div>

        </div>
      </div>
  )
}

function mstp(state) {
  return {
    activationSteps: state.activationSteps,
    datiWorkflow: state.datiWorkflow,
    campaign: state.campaign,
    selectedOffer: state.selectedOffer
  }
}

export default connect(mstp, { updateCurrentStep, setSelectedOffer, toggleToast, toggleSpinner })(Sottoscrizione);
