/*
 TIPOLOGIE DI ACCESSO
 0: visibile a tutti gli utenti (non autenticati, autenticati e autenticati con privilegi)
 1: visibile solo agli utenti non autenticati
 2: visibile a tutti utenti autenticati
 3: visibile solo agli utenti autenticati con un determinato ruolo

 In caso di accesso di tipo 3 bisogna indicare nell'array "roles" quali possono accedere alla pagina.
 Il tipo di dato da inserire all'interno di "roles" può essere di qualsiasi tipo (numero, stringa ecc.)

 Per impostare un path nei route senza farlo comparire nei link basta mettere il name come stringa vuota
 */

import React from 'react';
import Login from "../pages/Login/Login";

/*
  ATTENZIONE! Usare sempre il lazy loading
 */
//const Login = React.lazy(() => import ('../pages/Login/Login'));
// const Home = React.lazy(() => import('../pages/Home/Home'));
const SetNewPassword = React.lazy(() => import('../pages/Login/RecuperaPassword/SetNewPassword'));
const ModificaPassword = React.lazy(() => import('../pages/Login/ModificaPassword/ModificaPassword'));
const ModificaEmail = React.lazy(() => import('../pages/AreaPersonale/ModificaEmail/ModificaEmail'));
const AreaPersonale = React.lazy(() => import('../pages/AreaPersonale/AreaPersonale'));
const ModificaTelefono = React.lazy(() => import('../pages/AreaPersonale/ModificaTelefono/ModificaTelefono'));
const VerificaCambioRid = React.lazy(() => import('../pages/AreaPersonale/ModalitaPagamento/VerificaCambioMetodo/VerificaCambioRid'))
const VerificaCambioCarta = React.lazy(() => import('../pages/AreaPersonale/ModalitaPagamento/VerificaCambioMetodo/VerificaCambioCarta'))
const VerificaInvoicePayment = React.lazy(() => import('../pages/AreaPersonale/InvoicePayment/VerificaInvoicePayment'))
// const PaginaOfferta = React.lazy(() => import('../pages/PaginaOfferta/PaginaOfferta'));
// const PaginaOffertaSecondaria = React.lazy(() => import ('../pages/PaginaOfferta/PaginaOffertaSecondaria/PaginaOffertaSecondaria'));
// const Negozi = React.lazy(() => import('../pages/Negozi/Negozi'));
// const Rete = React.lazy(() => import('../pages/Rete/Rete'));
// const About = React.lazy(() => import('../pages/About/About'));
// const Contatti = React.lazy(() => import('../pages/Contatti/Contatti'));
// const AgevolazioniTariffarie = React.lazy(() => import('../pages/AgevolazioniTariffarie/AgevolazioniTariffarie'));
// const Copyright = React.lazy(() => import('../pages/Copyright/Copyright'));
// const Privacy = React.lazy(() => import('../pages/Privacy/Privacy'));
// const NoteLegali = React.lazy(() => import('../pages/NoteLegali/NoteLegali'));
// const TrasparenzaTariffaria = React.lazy(() => import('../pages/TrasparenzaTariffaria/TrasparenzaTariffaria'));
// const Trasparenza = React.lazy(() => import('../pages/Trasparenza/Trasparenza'));
// const ProceduraControversie = React.lazy(() => import('../pages/ProceduraControversie/ProceduraControversie'));
// const MappaSito = React.lazy(() => import('../pages/MappaSito/MappaSito'));
const Assistenza = React.lazy(() => import('../pages/Assistenza/Assistenza'));
// const EsitoVerificaCopertura = React.lazy(() => import('../pages/EsitoVerificaCopertura/EsitoVerificaCopertura'));
// const Sottoscrizione = React.lazy(() => import('../pages/Sottoscrizione/Sottoscrizione'));
// const DatiContatto = React.lazy(() => import('../pages/Sottoscrizione/Steps/DatiContatto'));
// const DatiContrattuali = React.lazy(() => import('../pages/Sottoscrizione/Steps/DatiContrattuali'));
const VerificaOtp = React.lazy(() => import('../pages/Sottoscrizione/Steps/VerificaOtp'));
// const DatiPagamento = React.lazy(() => import('../pages/Sottoscrizione/Steps/DatiPagamento'));
// const AccettazioneCgc = React.lazy(() => import('../pages/Sottoscrizione/Steps/AccettazioneCgc'));
// const WebPrivacyAndCookiePolicy = React.lazy(() => import('../pages/WebPrivacyAndCookiePolicy/WebPrivacyAndCookiePolicy'));
const VerificaPagamento = React.lazy(() => import('../pages/Sottoscrizione/VerificaPagamento/VerificaPagamento'));
const VerificaPagamentoRID = React.lazy(() => import('../pages/Sottoscrizione/VerificaPagamentoRID/VerificaPagamentoRID'));
// const SottoscrizioneRiuscita = React.lazy(() => import('../pages/Sottoscrizione/SottoscrizioneRiuscita/SottoscrizioneRiuscita'));
const SupportoCommerciale = React.lazy(() => import('../pages/Assistenza/SupportoCommerciale'));
const SupportoAmministrativo = React.lazy(() => import('../pages/Assistenza/SupportoAmministrativo'));
const SupportoTecnico = React.lazy(() => import('../pages/Assistenza/SupportoTecnico'));
// const Tecnologia = React.lazy(() => import('../pages/Tecnologia/Tecnologia'));
const Contratti = React.lazy(() => import('../pages/Assistenza/Contratti'));
// const SelezioneVAS = React.lazy(() => import('../pages/Sottoscrizione/SelezioneVAS/SelezioneVAS'));

// Array contente tutte le pagine con relative configurazioni
export const routes = [
    // {
    //     path: "/*",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Login,
    //     access: [1],
    //     private: false,
    //     isWrapped: false,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    {
        path: "/",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: Login,
        access: [1],
        private: false,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/modifica-password",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: ModificaPassword,
        access: [2],
        private: false,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/verifica-email/:token",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: ModificaEmail,
        access: [0],
        private: false,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/recupera-password/:token",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: SetNewPassword,
        access: [1],
        private: false,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/area-personale",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: AreaPersonale,
        access: [2],
        private: true,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/modifica-telefono",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: ModificaTelefono,
        access: [2],
        private: true,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/modifica-rid",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaCambioRid,
        access: [0],
        private: true,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/modifica-carta",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaCambioCarta,
        access: [0],
        private: true,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/verifica-invoice-payment",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaInvoicePayment,
        access: [0],
        private: true,
        isWrapped: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/login",
        name: "Home",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: Login,
        access: [0],
        private: false,
        isWrapped: false,
        inMappaSito: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    // {
    //     path: "/dettaglio-offerta",
    //     name: "Dettaglio Offerta",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: PaginaOfferta,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     inMappaSito: true,
    //     isOfferPage: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    /*{
        path: "/dettaglio-offerta-secondaria",
        name: "Dettaglio Offerta secondaria",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: PaginaOffertaSecondaria,
        access: [0],
        private: false,
        isWrapped: false,
        inMappaSito: true,
        isOfferPage: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },*/
    // {
    //     path: "/sottoscrivi-offerta",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Sottoscrizione,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/selezione-vas",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: SelezioneVAS,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     isStepper: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/dati-contatto",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: DatiContatto,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     isStepper: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/dati-contrattuali",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: DatiContrattuali,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     isStepper: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    {
        path: "/verifica-otp/:token",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaOtp,
        access: [0],
        private: false,
        isWrapped: false,
        isStepper: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {   // Temporary da eliminare
        path: "/verifica-otp/:token/justspeed",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaOtp,
        access: [0],
        private: false,
        isWrapped: false,
        isStepper: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    // {   // Route specifica per la verifica dell'otp nel flow cartaceo
    //     path: "/verifica-otp/paper/:token",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: VerificaOtp,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     isStepper: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/dati-pagamento",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: DatiPagamento,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     isStepper: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    {
        path: "/verifica-pagamento",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaPagamento,
        access: [0],
        private: false,
        isWrapped: false,
        isStepper: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/verifica-pagamento-rid",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaPagamentoRID,
        access: [0],
        private: false,
        isWrapped: false,
        isStepper: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    { // DA ELIMINARE
        path: "/verifica-pagamento/temp",
        name: "",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: VerificaPagamento,
        access: [0],
        private: false,
        isWrapped: false,
        isStepper: false,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    // {
    //     path: "/sottoscrizione-riuscita/:landgingType",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: SottoscrizioneRiuscita,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     isStepper: false,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/accettazione-cgc",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: AccettazioneCgc,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     isStepper: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/esito-verifica",
    //     name: "",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: EsitoVerificaCopertura,
    //     access: [0],
    //     private: false,
    //     isWrapped: false,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    //     isOfferPage: true
    // },
    // {
    //     path: "/negozi",
    //     name: "Negozi",
    //     identity: "negozi",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Negozi,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: false,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     //Path e component name sostituito nel momento della migrazione a Onetrust
    //     path: "/web-privacy-cookie-policy",
    //     name: "Web Privacy & Cookie Policy",
    //     identity: "web-privacy-cookie-policy",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: WebPrivacyAndCookiePolicy,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/rete",
    //     name: "Rete",
    //     identity: "rete",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Rete,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: false,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/about-us",
    //     name: "Chi siamo",
    //     identity: "chi-siamo",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: About,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/contatti",
    //     name: "Contatti",
    //     identity: "contatti",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Contatti,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/agevolazioni",
    //     name: "Agevolazioni Tariffarie",
    //     identity: "agevolazioni",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: AgevolazioniTariffarie,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/copyright",
    //     name: "Copyright",
    //     identity: "copyright",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Copyright,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/privacy",
    //     name: "Privacy",
    //     identity: "privacy",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Privacy,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/note-legali",
    //     name: "Note Legali",
    //     identity: "note-legali",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: NoteLegali,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/trasparenza",
    //     name: "Trasparenza",
    //     identity: "trasparenza",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Trasparenza,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/trasparenza-tariffaria",
    //     name: "Trasparenza Tariffaria",
    //     identity: "trasparenza-tariffaria",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: TrasparenzaTariffaria,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/procedura-controversie",
    //     name: "Procedura Controversie",
    //     identity: "procedura-controversie",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: ProceduraControversie,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    // {
    //     path: "/mappa-del-sito",
    //     name: "Mappa del Sito",
    //     identity: "mappa-del-sito",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: MappaSito,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    {
        path: "/assistenza",
        name: "Supporto",
        identity: "assistenza",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: Assistenza,
        access: [0],
        private: false,
        isWrapped: true,
        inMappaSito: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/assistenza/supporto-commerciale",
        name: "Supporto Commerciale",
        identity: "supporto-commerciale",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: SupportoCommerciale,
        access: [0],
        private: false,
        isWrapped: true,
        inMappaSito: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/assistenza/supporto-amministrativo",
        name: "Supporto Amministrativo",
        identity: "supporto-amministrativo",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: SupportoAmministrativo,
        access: [0],
        private: false,
        isWrapped: true,
        inMappaSito: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    {
        path: "/assistenza/supporto-tecnico",
        name: "Supporto Tecnico",
        identity: "supporto-tecnico",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: SupportoTecnico,
        access: [0],
        private: false,
        isWrapped: true,
        inMappaSito: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    },
    // {
    //     path: "/tecnologia",
    //     name: "Tecnologia FTTH",
    //     identity: "tecnologia",
    //     isExternalPage: false,
    //     logo: {
    //         icon: null,
    //         img: null,
    //         svg: null
    //     },
    //     component: Tecnologia,
    //     access: [0],
    //     private: false,
    //     isWrapped: true,
    //     inMappaSito: true,
    //     roles: [],
    //     style: {
    //         color: '#005A8E',
    //         fontWeight: 'bold',
    //         textTransform: 'uppercase',
    //         opacity: .5
    //     },
    // },
    {
        path: "/assistenza/contratti",
        name: "Contratti",
        identity: "contratti",
        isExternalPage: false,
        logo: {
            icon: null,
            img: null,
            svg: null
        },
        component: Contratti,
        access: [0],
        private: false,
        isWrapped: true,
        inMappaSito: true,
        roles: [],
        style: {
            color: '#005A8E',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            opacity: .5
        },
    }
];
