import React, { Children, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import axiosInstance from "../../api/interceptor";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { regexp, checkCellNum, ValidateEmail } from '../utils/utils';
import { toponymicParticles } from '../utils/toponymicParticles';
import classNames from 'classnames';
import '../HomeContactSection/HomeContactSection.scss';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLinkem from '../ButtonLinkem/ButtonLinkem';
import { getComuniNexev } from '../../api/comuniEcap/api';
import { toggleToast } from '../../store/actionCreators/general';
import { debounce } from 'lodash';
import BootstrapSpinner from '../BootstrapSpinner/BootstrapSpinner';
import Autocomplete from '../Autocomplete/Autocomplete';

function AddressNotFound({ dati }) {

  const history = useHistory();

  const privacy_url = useSelector(state => state.privacy_url?.privacyLeadOneMonth);
  const campaign = useSelector(state => state.campaign);

  const dispatch = useDispatch();

  const [richestaEffettuata, setRichestaEffettuata] = useState(false);
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [telefono, setTelefono] = useState('');
  const [email, setEmail] = useState('');
  const [privacy_accepted, setPrivacy_accepted] = useState([
    { label: 'accetto', value: '1', checked: true },
    { label: 'non accetto', value: '0', checked: false }])
  const [cap, setCap] = useState(dati.cap);
  const [indirizzo, setIndirizzo] = useState(dati.indirizzo);
  const [civico, setCivico] = useState(dati.civico);
  const [provincia, setProvincia] = useState(dati.provincia);
  const [toponimo, setToponimo] = useState(dati.toponimo);

  // BLOCCO COMUNE START
  const [comune, setComune] = useState({
    value: '',
    valid: false,
    validate: value => (value && value.length > 0)
  });
  const [comuniOptions, setComuniOptions] = useState([]);

  const onChangeComune = ({ target: { value } }) => {
    const { validate } = comune;
    setComune({
      validate,
      value,
      valid: validate(value),
    });
    if (value.length > 2) {
      debouncedComuni(value);
    } else {
      setComuniOptions([])
    }
  };

  const fetchComuni = (search) => {
    setSpinnerComuneOn(true);
    getComuniNexev(search)
      .then(res => {
        setComuniOptions(res.data.data)
        setSpinnerComuneOn(false);
        setAutoCompleteComuneOpen(true);
      })
      .catch(e => {
        dispatch(toggleToast('e', e.messaggio));
        setSpinnerComuneOn(false);
      })
  };

  const debouncedComuni = useCallback(debounce(value => {
    fetchComuni(value);
    // eslint-disable-next-line
  }, 800), []);

  // Setto il valore iniziale se ho effettuato 
  // un verifica copertura
  const setInitialComune = () => {
    if (dati.comune.length > 0) {
      setComune({
        validate: comune.validate,
        value: dati.comune,
        valid: true
      })
    }
  };

  // Questo blocco ha lo scopo di verificare
  // che il campo comune sia scritto nel modo corretto
  const validateComune = () => {
    const find = comuniOptions.find((c) => c.COMUNE.toUpperCase() === comune.value.toUpperCase());
    setComune({
      ...comune, valid: find !== undefined
    });
    // Set automatico della provincia
    if (find) setProvincia(find.SIGLA)
    else setProvincia('');
  };

  useEffect(() => { validateComune() }, [comuniOptions, comune.value]) //eslint-disable-line

  useEffect(() => { setInitialComune() }, []); //eslint-disable-line

  // BLOCCO COMUNE END

  // States per validazione
  const [checkNome, setCheckNome] = useState(false);
  const [checkCognome, setCheckCognome] = useState(false);
  const [checkTelefono, setCheckTelefono] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPrivacy, setCheckPrivacy] = useState(false);
  const [checkCap, setCheckCap] = useState(false);
  const [checkIndirizzo, setCheckIndirizzo] = useState(false);
  const [checkCivico, setCheckCivico] = useState(false);
  // const [checkProvincia, setCheckProvincia] = useState(false);
  const [checkToponimo, setCheckToponimo] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);


  function checkValidation() {
    return (
      checkNome && checkCognome && checkTelefono &&
      checkEmail && checkPrivacy && comune.valid &&
      checkCap && checkIndirizzo && checkCivico &&
      // checkProvincia &&
      checkToponimo
    )
  }

  // Metodo per assicurarsi che il campo "Cellulare" abbia esclusivamente caratteri numerici
  function handlePhoneChange(e) {
    if (regexp.test(e.target.value) || e.target.value === "") {
      setTelefono(e.target.value)
    }
  }

  function handleRadioButtons(id) {
    const temp = privacy_accepted.map((el, idx) => idx === id ? { ...el, checked: true } : { ...el, checked: false })
    setPrivacy_accepted(temp);
  }

  async function submitForm() {
    const body = {
      city: comune.value,
      first_name: nome,
      last_name: cognome,
      phone_number: telefono,
      email,
      privacy_accepted: '1',
      coverage: {
        city: comune.value,
        province: provincia,
        toponymic_particle: toponimo,
        street_name: indirizzo,
        address_number: civico
      },
      utm_source: campaign?.utm_source ? campaign.utm_source : '',
      utm_medium: campaign?.utm_medium ? campaign.utm_medium : '',
      utm_campaign: campaign?.utm_campaign ? campaign.utm_campaign : '',
      utm_term: campaign?.utm_term ? campaign.utm_term : '',
      utm_content: campaign?.utm_content ? campaign.utm_content : ''
    }

    if (!wasSubmitted) setWasSubmitted(true);

    // Esegue la chiamata al BE solo se i campi sono stati campilati
    if (checkValidation()) {
      await axiosInstance.post(`/leads/create/address-not-found`, body, { 'Content-Type': 'multipart/form-data' })
        .then(() => {
          setRichestaEffettuata(true)
          ReactGA.event({
            category: 'Form',
            action: "Ricontattami Indirizzo",
            label: history.location.pathname
          });
          ReactPixel.track('Lead', { content_name: 'Ricontattami Indirizzo', content_category: 'Form', });
        })
        .catch(() => dispatch(toggleToast('e', "Errore nell'invio della segnalazione.")))
    }
  }

  // Verifico la validità dei campi ad ogni cambio
  useEffect(() => {
    setCheckNome(nome.length > 0);
    setCheckCognome(cognome.length > 0);
    setCheckEmail(ValidateEmail(email));
    setCheckTelefono(checkCellNum(telefono));
    setCheckPrivacy(privacy_accepted.filter(el => el.value = "1")[0].checked);
    setCheckCap(cap.length > 0);
    setCheckIndirizzo(indirizzo.length > 0);
    setCheckCivico(civico.length > 0);
    // setCheckProvincia(provincia.length > 0)
    setCheckToponimo(toponimo.length > 0);
  }, [nome, cognome, email, telefono, privacy_accepted, comune, cap, indirizzo, civico, /*provincia,*/ toponimo])

  // BLOCCO COMUNE START (Copia verifica copertura)

  const [autocompleteComuneStyle, setAutoCompleteComuneStyle] = useState({});
  const [autoCompleteComuneOpen, setAutoCompleteComuneOpen] = useState(false);
  const [spinnerComuneOn, setSpinnerComuneOn] = useState(false);

  // Impedisce l'uso del tasto tab per spostarsi su un altro input
  function preventTab(e) {
    if (e.keyCode === 9) e.preventDefault()
  };

  const inputComune = React.useRef();
  const divComune = React.useRef();

  const handleCloseComune = () => {
    setComune({ ...comune, value: '' });
    setAutoCompleteComuneOpen(false);
  };

  function handleChiusuraDiv(nomeCampo) {
    if (nomeCampo === 'comune') handleCloseComune();
  };

  const noResult = (nomeCampo = 'comune') => <li onClick={() => handleChiusuraDiv(nomeCampo)}>
    La ricerca non ha prodotto risultati
  </li>;

  function handleClickOutsideList(e) {
    if (
      divComune?.current && inputComune?.current &&
      !divComune?.current.contains(e.target) &&
      !inputComune?.current.contains(e.target) &&
      (divComune?.current.firstChild.clientHeight !== 0)) {
      handleCloseComune();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideList);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!comune.value || comune.value.length < 3) {
      setComuniOptions([]);
      setAutoCompleteComuneOpen(false);
    }
  }, []); // eslint-disable-line

  const onClickAutocompleteComune = (el) => {
    setComune({ ...comune, value: el.COMUNE });
    setAutoCompleteComuneOpen(false);
  }

  const onFocusComune = () => {
    let style = {
      width: inputComune.current.getBoundingClientRect().width - 31,
      left: 18,
    }
    // Apriamo la select sopra/sotto l'input, in base alla posizione dello stesso
    style = inputComune.current.getBoundingClientRect().top <
      inputComune.current.getBoundingClientRect().bottom ?
      { ...style, top: inputComune.current.getBoundingClientRect().height + 1 } :
      { ...style, bottom: inputComune.current.getBoundingClientRect().height + 1 };
    setAutoCompleteComuneStyle(style);
  };

  // BLOCCO COMUNE END

  return (
    <>
      {richestaEffettuata
        ? <div className="my-3 text-center text-white">
          <p className="jeko-medium-font">
            Abbiamo ricevuto correttamente la tua richiesta.
            <br />
            Un nostro operatore ti ricontatterà al più presto dal numero
            <br />
            <strong>02 89 890</strong>
          </p>
        </div>
        : <div className="text-white" style={{ padding: '3% 10%' }}>
          <h4 className="color-linkem-red responsive-title">
            Non trovi il tuo indirizzo? Lasciaci i tuoi dati
          </h4>
          <p>Ti ricontatteremo per verificare insieme la copertura</p>
          <div className="form-group mt-4">
            <div className="row">
              <div className="col-md-6 col-sm-12 mb-3 p-0">
                <input
                  type="text"
                  className={classNames("form-control border-radius-20 p-3 mr-md-1", { "is-invalid": !checkNome && wasSubmitted })}
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  placeholder="Nome" />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkNome && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-3 p-0">
                <input
                  type="text"
                  className={classNames("form-control border-radius-20 p-3 ml-md-1", { "is-invalid": !checkCognome && wasSubmitted })}
                  value={cognome}
                  onChange={e => setCognome(e.target.value)}
                  placeholder="Cognome" />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkCognome && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div>
              <div className="col-md-6 col-sm-12 p-0 mb-3">
                <input
                  type="email"
                  className={classNames("form-control mr-md-1 p-3 border-radius-20", { "is-invalid": !checkEmail && wasSubmitted })}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="E-mail" />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkEmail && wasSubmitted) || !wasSubmitted })}>
                  Indirizzo e-mail non valido
                </div>
              </div>
              <div className="col-md-6 col-sm-12 p-0 mb-3">
                <input
                  type="text"
                  className={classNames("form-control ml-md-1 p-3 border-radius-20", { "is-invalid": !checkTelefono && wasSubmitted })}
                  value={telefono}
                  onChange={handlePhoneChange}
                  maxLength={12}
                  placeholder="Cellulare" />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkTelefono && wasSubmitted) || !wasSubmitted })}>
                  Verifica la correttezza del dato
                </div>
              </div>

              <div className='col-md-12 col-sm-12 p-0 mb-3'>
                <input
                  type="text"
                  ref={inputComune}
                  className="form-control p-3 border-radius-20"
                  name="comune"
                  onKeyDown={preventTab}
                  onChange={onChangeComune}
                  value={comune.value}
                  placeholder="Comune"
                  onFocus={onFocusComune}
                />
                <BootstrapSpinner extraClass={classNames("input-field-spinner", { 'd-none': !spinnerComuneOn })} />
                <span ref={divComune}>
                  <Autocomplete closed={!autoCompleteComuneOpen} style={autocompleteComuneStyle}>
                    {!comuniOptions ? noResult('comune') :
                      Children.toArray(comuniOptions.map(el =>
                        <li onClick={() => onClickAutocompleteComune(el)}>
                          {el.COMUNE}
                        </li>
                      ))
                    }
                  </Autocomplete>
                </span>
                <div className={classNames("small text-danger ml-3", { 'd-none': (comune.valid && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div>
              {/* <AutocompleteComuni
                {
                  Children.toArray(comuniOptions.map(c => (
                    <option key={c.KEY} value={c.COMUNE}>
                      {c.COMUNE}
                    </option>
                  )))
                }
              </AutocompleteComuni> */}

              {/* <div className="col-md-9 col-sm-12 p-0 mb-3">
                <input
                  type="text"
                  className={classNames("form-control mr-md-1 p-3 border-radius-20", { "is-invalid": !checkComune && wasSubmitted })}
                  value={comune.value}
                  onChange={e => setComune(e.target.value)}
                  placeholder="Comune"
                />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkComune && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div> */}

              {/* Rimosso su indicazione di Andrea Nexev */}
              {/* <div className="col-md-3 col-sm-12 p-0 mb-3">
                <input
                  type="text"
                  className={classNames("form-control ml-md-1 p-3 border-radius-20", { "is-invalid": !checkProvincia && wasSubmitted })}
                  value={provincia}
                  maxLength={2}
                  onChange={e => setProvincia(e.target.value)}
                  placeholder="Provincia"
                />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkProvincia && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div> */}
              <div className="col-md-4 col-sm-12 p-0 mb-3">
                <select className={classNames("form-control mb-2 border-radius-20", { "is-invalid": !checkToponimo && wasSubmitted })}
                  onChange={e => setToponimo(e.target.value)} value={toponimo}>
                  <option value="">Particella Toponomatica</option>
                  {
                    toponymicParticles.map((el, idx) => <option key={idx} value={el}>{el}</option>)
                  }
                </select>
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkToponimo && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div>

              <div className="col-md-8 col-sm-12 p-0 mb-3">
                <input
                  type="text"
                  className={classNames("form-control ml-md-1 p-3 border-radius-20", { "is-invalid": !checkIndirizzo && wasSubmitted })}
                  value={indirizzo}
                  onChange={e => setIndirizzo(e.target.value)}
                  placeholder="Indirizzo" />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkIndirizzo && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div>
              <div className="col-md-6 col-sm-12 p-0 mb-3">
                <input
                  type="text"
                  className={classNames("form-control mr-md-1 p-3 border-radius-20", { "is-invalid": !checkCivico && wasSubmitted })}
                  value={civico}
                  onChange={e => setCivico(e.target.value)}
                  placeholder="Civico" />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkCivico && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div>
              <div className="col-md-6 col-sm-12 p-0 mb-3">
                <input
                  type="text"
                  className={classNames("form-control ml-md-1 p-3 border-radius-20", { "is-invalid": !checkCap && wasSubmitted })}
                  value={cap}
                  maxLength={5}
                  onChange={e => setCap(e.target.value)}
                  placeholder="Cap" />
                <div className={classNames("small text-danger ml-3", { 'd-none': (checkCap && wasSubmitted) || !wasSubmitted })}>
                  Campo obbligatorio
                </div>
              </div>
              <div className="col-12 mt-0">
                <p className="text-privacy">Cliccando accetto, dichiaro di aver preso visione dell'
                  <a
                    href={process.env.REACT_APP_BASENAME_STRAPI + privacy_url}
                    target="_blank" rel="noopener noreferrer"
                  >
                    informativa</a> qui riportata e richiedo di essere ricontattato.
                </p>
              </div>
              <div className="col-12 my-2 px-0 d-flex justify-content-end">
                {
                  privacy_accepted.map((el, idx) => (
                    <div key={idx}
                      className={`form-check ${idx === 0 ? 'col-lg-3 col-md-4 col-sm-4' : 'col-lg-4 col-md-5 col-sm-4'} col-xl-3 col-xs-6 pr-0 text-right`}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name={el.name}
                        value={el.value}
                        checked={el.checked}
                        onChange={() => handleRadioButtons(idx)}
                      />
                      <label className="contact-form-check-label">
                        {el.label}
                      </label>
                    </div>
                  ))
                }
              </div>
              <div className={classNames("small text-danger ml-3 w-100 text-center", { 'd-none': (checkPrivacy && wasSubmitted) || !wasSubmitted })}>
                L'accettazione della privacy è obbligatoria
              </div>

              <ButtonLinkem
                polarizedRed
                className="text-uppercase border-radius-20 mt-3 ml-auto mr-auto pl-3 pr-3 pt-2 pb-2"
                onClick={submitForm}>
                <i className="fas fa-phone-volume mr-3" />
                <span className="pt-1 termina-demi">Voglio essere ricontattato</span>
              </ButtonLinkem>
            </div>
          </div>
        </div>
      }
    </>
  )
}

AddressNotFound.propTypes = {
  dati: PropTypes.shape({
    comune: PropTypes.any,
    provincia: PropTypes.any,
    toponimo: PropTypes.any,
    cap: PropTypes.any,
    indirizzo: PropTypes.any,
    civico: PropTypes.any
  })
};

export default AddressNotFound;
