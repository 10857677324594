export const Operatori = [
  {
    "descrizione": "TELECOM ITALIA",
    "codiceCow": "TLC",
    "descrizioneDSL": "TELECOM ITALIA / ALICE"
  },
  {
    "descrizione": "FASTWEB S.P.A.",
    "codiceCow": "MTW",
    "descrizioneDSL": "FASTWEB S.P.A."
  },
  {
    "descrizione": "LIBERO INFOSTRADA",
    "codiceCow": "WIN",
    "descrizioneDSL": "LIBERO INFOSTRADA"
  },
  {
    "descrizione": "TELE 2",
    "codiceCow": "TL2",
    "descrizioneDSL": "TELE 2"
  },
  {
    "descrizione": "TISCALI",
    "codiceCow": "TIS",
    "descrizioneDSL": "TISCALI"
  },
  {
    "descrizione": "VODAFONE",
    "codiceCow": "OPI",
    "descrizioneDSL": "VODAFONE"
  },
  {
    "descrizione": "2BITE S.R.L.",
    "codiceCow": "BIE",
    "descrizioneDSL": "2BITE S.R.L."
  },
  {
    "descrizione": "3P SYSTEM S.R.L.",
    "codiceCow": "PPS",
    "descrizioneDSL": "3P SYSTEM S.R.L."
  },
  {
    "descrizione": "ABACO INFORMATICA S.R.L.",
    "codiceCow": "ABA",
    "descrizioneDSL": "ABACO INFORMATICA S.R.L."
  },
  {
    "descrizione": "ABILENE NET SOLUTIONS S.R.L.",
    "codiceCow": "ABI",
    "descrizioneDSL": "ABILENE NET SOLUTIONS S.R.L."
  },
  {
    "descrizione": "ACANTHO S.P.A.",
    "codiceCow": "ACH",
    "descrizioneDSL": "ACANTHO S.P.A."
  },
  {
    "descrizione": "ACI INFORMATICA",
    "codiceCow": "ACI",
    "descrizioneDSL": "ACI INFORMATICA"
  },
  {
    "descrizione": "ACN ITALIA COMMUNICATIONS S.R.L.",
    "codiceCow": "AIO",
    "descrizioneDSL": "ACN ITALIA COMMUNICATIONS S.R.L."
  },
  {
    "descrizione": "ACONET S.R.L.",
    "codiceCow": "ACO",
    "descrizioneDSL": "ACONET S.R.L."
  },
  {
    "descrizione": "ACTIVE NETWORK S.P.A.",
    "codiceCow": "ACN",
    "descrizioneDSL": "ACTIVE NETWORK S.P.A."
  },
  {
    "descrizione": "ADR TEL S.P.A.",
    "codiceCow": "ADT",
    "descrizioneDSL": "ADR TEL S.P.A."
  },
  {
    "descrizione": "AEMCOM S.R.L.",
    "codiceCow": "AEM",
    "descrizioneDSL": "AEMCOM S.R.L."
  },
  {
    "descrizione": "AEMNET S.P.A.",
    "codiceCow": "AEN",
    "descrizioneDSL": "AEMNET S.P.A."
  },
  {
    "descrizione": "ALIDA SRL",
    "codiceCow": "ALD",
    "descrizioneDSL": "ALIDA SRL"
  },
  {
    "descrizione": "AMBROGIO SRL",
    "codiceCow": "AMB",
    "descrizioneDSL": "AMBROGIO SRL"
  },
  {
    "descrizione": "AMPERSAND S.R.L.",
    "codiceCow": "AMP",
    "descrizioneDSL": "AMPERSAND S.R.L."
  },
  {
    "descrizione": "AMT SERVICES SRL",
    "codiceCow": "ATS",
    "descrizioneDSL": "AMT SERVICES SRL"
  },
  {
    "descrizione": "ANTEA SRL",
    "codiceCow": "ANE",
    "descrizioneDSL": "ANTEA SRL"
  },
  {
    "descrizione": "ARCATRON S.R.L.",
    "codiceCow": "AXN",
    "descrizioneDSL": "ARCATRON S.R.L."
  },
  {
    "descrizione": "ARIA SPA",
    "codiceCow": "ARA",
    "descrizioneDSL": "ARIA SPA"
  },
  {
    "descrizione": "ARUBA SPA",
    "codiceCow": "ARU",
    "descrizioneDSL": "ARUBA SPA"
  },
  {
    "descrizione": "ASCO TLC SPA",
    "codiceCow": "ASC",
    "descrizioneDSL": "ASCO TLC SPA"
  },
  {
    "descrizione": "ATCALL S.R.L.",
    "codiceCow": "AXA",
    "descrizioneDSL": "ATCALL S.R.L."
  },
  {
    "descrizione": "BBBELL S.R.L.",
    "codiceCow": "BBS",
    "descrizioneDSL": "BBBELL S.R.L."
  },
  {
    "descrizione": "BELLNET INTERNATIONAL S.R.L.",
    "codiceCow": "BEI",
    "descrizioneDSL": "BELLNET INTERNATIONAL S.R.L."
  },
  {
    "descrizione": "BIGTLC SRL",
    "codiceCow": "BIG",
    "descrizioneDSL": "BIGTLC SRL"
  },
  {
    "descrizione": "BRENNERCOM S.P.A.",
    "codiceCow": "BRE",
    "descrizioneDSL": "BRENNERCOM S.P.A."
  },
  {
    "descrizione": "BRIANTEL S.R.L.",
    "codiceCow": "BIA",
    "descrizioneDSL": "BRIANTEL S.R.L."
  },
  {
    "descrizione": "BT ENIA TELECOMUNICAZIONI S.P.A.",
    "codiceCow": "AMS",
    "descrizioneDSL": "BT ENIA TELECOMUNICAZIONI S.P.A."
  },
  {
    "descrizione": "BT ITALIA S.P.A.",
    "codiceCow": "ALB",
    "descrizioneDSL": "BT ITALIA S.P.A."
  },
  {
    "descrizione": "BYTEL SERVICES S.N.C. DI MANFRE JENNY \u0026 C.",
    "codiceCow": "BTE",
    "descrizioneDSL": "BYTEL SERVICES S.N.C. DI MANFRE JENNY \u0026 C."
  },
  {
    "descrizione": "CDLAN S.R.L.",
    "codiceCow": "CDL",
    "descrizioneDSL": "CDLAN S.R.L."
  },
  {
    "descrizione": "CESENA NET S.R.L.",
    "codiceCow": "CES",
    "descrizioneDSL": "CESENA NET S.R.L."
  },
  {
    "descrizione": "CILENTO TLC S.R.L.S.",
    "codiceCow": "CLN",
    "descrizioneDSL": "CILENTO TLC S.R.L.S."
  },
  {
    "descrizione": "CLIO S.R.L.",
    "codiceCow": "CLI",
    "descrizioneDSL": "CLIO S.R.L."
  },
  {
    "descrizione": "CLOUDFIRE S.R.L.",
    "codiceCow": "CLR",
    "descrizioneDSL": "CLOUDFIRE S.R.L."
  },
  {
    "descrizione": "CODEMA DI DE MARTINIS ROSA MARIA",
    "codiceCow": "CDS",
    "descrizioneDSL": "CODEMA DI DE MARTINIS ROSA MARIA"
  },
  {
    "descrizione": "CODING S.R.L.",
    "codiceCow": "COI",
    "descrizioneDSL": "CODING S.R.L."
  },
  {
    "descrizione": "COLT TELECOM S.P.A.",
    "codiceCow": "COL",
    "descrizioneDSL": "COLT TELECOM S.P.A."
  },
  {
    "descrizione": "COMEG S.P.A.",
    "codiceCow": "CME",
    "descrizioneDSL": "COMEG S.P.A."
  },
  {
    "descrizione": "CONNECTING PROJECT S.R.L.",
    "codiceCow": "CPJ",
    "descrizioneDSL": "CONNECTING PROJECT S.R.L."
  },
  {
    "descrizione": "CONNETICAL",
    "codiceCow": "COA",
    "descrizioneDSL": "CONNETICAL"
  },
  {
    "descrizione": "CONSORZIO METROLINK",
    "codiceCow": "TDC",
    "descrizioneDSL": "CONSORZIO METROLINK"
  },
  {
    "descrizione": "CONVERGENZE S.P.A.",
    "codiceCow": "CVG",
    "descrizioneDSL": "CONVERGENZE S.P.A."
  },
  {
    "descrizione": "CRITICALCASE S.R.L.",
    "codiceCow": "CRC",
    "descrizioneDSL": "CRITICALCASE S.R.L."
  },
  {
    "descrizione": "CSINFO S.P.A.",
    "codiceCow": "CSI",
    "descrizioneDSL": "CSINFO S.P.A."
  },
  {
    "descrizione": "CWNET S.R.L.",
    "codiceCow": "CWN",
    "descrizioneDSL": "CWNET S.R.L."
  },
  {
    "descrizione": "DAILY TELECOM MOBILE S.R.L.",
    "codiceCow": "DTL",
    "descrizioneDSL": "DAILY TELECOM MOBILE S.R.L."
  },
  {
    "descrizione": "DATABASE INFORMATICA SRL",
    "codiceCow": "DAB",
    "descrizioneDSL": "DATABASE INFORMATICA SRL"
  },
  {
    "descrizione": "DATAONE S.R.L.",
    "codiceCow": "DOE",
    "descrizioneDSL": "DATAONE S.R.L."
  },
  {
    "descrizione": "DBNET S.P.A.",
    "codiceCow": "DBN",
    "descrizioneDSL": "DBNET S.P.A."
  },
  {
    "descrizione": "DIALOGA SERVICIOS INTERACTIVOS S.A.",
    "codiceCow": "DIO",
    "descrizioneDSL": "DIALOGA SERVICIOS INTERACTIVOS S.A."
  },
  {
    "descrizione": "DIEFFEITALIA.IT S.R.L.",
    "codiceCow": "DIY",
    "descrizioneDSL": "DIEFFEITALIA.IT S.R.L."
  },
  {
    "descrizione": "DIGITEL ITALIA S.P.A.",
    "codiceCow": "DIG",
    "descrizioneDSL": "DIGITEL ITALIA S.P.A."
  },
  {
    "descrizione": "E-MIND SRL",
    "codiceCow": "EMI",
    "descrizioneDSL": "E-MIND SRL"
  },
  {
    "descrizione": "EASYCONN S.R.L.",
    "codiceCow": "EDC",
    "descrizioneDSL": "EASYCONN S.R.L."
  },
  {
    "descrizione": "EASYNET ITALIA SPA",
    "codiceCow": "EAS",
    "descrizioneDSL": "EASYNET ITALIA SPA"
  },
  {
    "descrizione": "EHINET SRL",
    "codiceCow": "EHI",
    "descrizioneDSL": "EHINET SRL"
  },
  {
    "descrizione": "EL.COM. S.R.L.",
    "codiceCow": "ECP",
    "descrizioneDSL": "EL.COM. S.R.L."
  },
  {
    "descrizione": "ELSYNET S.R.L.",
    "codiceCow": "ELY",
    "descrizioneDSL": "ELSYNET S.R.L."
  },
  {
    "descrizione": "EMAXY S.R.L.",
    "codiceCow": "AXY",
    "descrizioneDSL": "EMAXY S.R.L."
  },
  {
    "descrizione": "ENEGAN S.P.A.",
    "codiceCow": "ENK",
    "descrizioneDSL": "ENEGAN S.P.A."
  },
  {
    "descrizione": "ENTER S.R.L.",
    "codiceCow": "ENT",
    "descrizioneDSL": "ENTER S.R.L."
  },
  {
    "descrizione": "ESTRACOM S.P.A.",
    "codiceCow": "CNS",
    "descrizioneDSL": "ESTRACOM S.P.A."
  },
  {
    "descrizione": "EUTELIA S.P.A.",
    "codiceCow": "PLU",
    "descrizioneDSL": "EUTELIA S.P.A."
  },
  {
    "descrizione": "EXTRATEL S.R.L.",
    "codiceCow": "EXT",
    "descrizioneDSL": "EXTRATEL S.R.L."
  },
  {
    "descrizione": "FASTFONE S.R.L.",
    "codiceCow": "FNE",
    "descrizioneDSL": "FASTFONE S.R.L."
  },
  {
    "descrizione": "FASTMEDIA S.R.L.",
    "codiceCow": "FSM",
    "descrizioneDSL": "FASTMEDIA S.R.L."
  },
  {
    "descrizione": "FASTNET SPA",
    "codiceCow": "FAS",
    "descrizioneDSL": "FASTNET SPA"
  },
  {
    "descrizione": "FIBERING S.P.A.",
    "codiceCow": "DIL",
    "descrizioneDSL": "FIBERING S.P.A."
  },
  {
    "descrizione": "FLY NET S.P.A.",
    "codiceCow": "FLN",
    "descrizioneDSL": "FLY NET S.P.A."
  },
  {
    "descrizione": "FONIACOM S.P.A.",
    "codiceCow": "FON",
    "descrizioneDSL": "FONIACOM S.P.A."
  },
  {
    "descrizione": "FONTEL S.P.A.",
    "codiceCow": "FNT",
    "descrizioneDSL": "FONTEL S.P.A."
  },
  {
    "descrizione": "GENY COMMUNICATIONS S.R.L.",
    "codiceCow": "GEY",
    "descrizioneDSL": "GENY COMMUNICATIONS S.R.L."
  },
  {
    "descrizione": "GLOBALNET CONSULTING",
    "codiceCow": "GLC",
    "descrizioneDSL": "GLOBALNET CONSULTING"
  },
  {
    "descrizione": "GREEN ICN S.P.A.",
    "codiceCow": "ICN",
    "descrizioneDSL": "GREEN ICN S.P.A."
  },
  {
    "descrizione": "GTEL S.R.L.",
    "codiceCow": "GTL",
    "descrizioneDSL": "GTEL S.R.L."
  },
  {
    "descrizione": "HAL SERVICE S.R.L.",
    "codiceCow": "HAL",
    "descrizioneDSL": "HAL SERVICE S.R.L."
  },
  {
    "descrizione": "I\u0026F COMMERCIALE S.R.L",
    "codiceCow": "IEF",
    "descrizioneDSL": "I\u0026F COMMERCIALE S.R.L"
  },
  {
    "descrizione": "ICT VALLE UMBRA S.R.L.",
    "codiceCow": "VUB",
    "descrizioneDSL": "ICT VALLE UMBRA S.R.L."
  },
  {
    "descrizione": "IFM INFOMASTER S.P.A.",
    "codiceCow": "IMF",
    "descrizioneDSL": "IFM INFOMASTER S.P.A."
  },
  {
    "descrizione": "INDUSTRIE DIGITALI S.R.L.",
    "codiceCow": "IND",
    "descrizioneDSL": "INDUSTRIE DIGITALI S.R.L."
  },
  {
    "descrizione": "INFRACOM ITALIA S.P.A.",
    "codiceCow": "ETS",
    "descrizioneDSL": "INFRACOM ITALIA S.P.A."
  },
  {
    "descrizione": "INTERCOM SRL",
    "codiceCow": "ITM",
    "descrizioneDSL": "INTERCOM SRL"
  },
  {
    "descrizione": "INTERMATICA SPA",
    "codiceCow": "IRM",
    "descrizioneDSL": "INTERMATICA SPA"
  },
  {
    "descrizione": "INTERNET ONE S.R.L.",
    "codiceCow": "ION",
    "descrizioneDSL": "INTERNET ONE S.R.L."
  },
  {
    "descrizione": "INTERPLANET S.R.L.",
    "codiceCow": "INP",
    "descrizioneDSL": "INTERPLANET S.R.L."
  },
  {
    "descrizione": "INTRED SPA",
    "codiceCow": "IRD",
    "descrizioneDSL": "INTRED SPA"
  },
  {
    "descrizione": "IRPINIA NET-COM S.R.L.",
    "codiceCow": "IRP",
    "descrizioneDSL": "IRPINIA NET-COM S.R.L."
  },
  {
    "descrizione": "ISI LINE S.R.L.",
    "codiceCow": "ILN",
    "descrizioneDSL": "ISI LINE S.R.L."
  },
  {
    "descrizione": "IT.GATE SPA",
    "codiceCow": "TGE",
    "descrizioneDSL": "IT.GATE SPA"
  },
  {
    "descrizione": "ITAL-TEL S.R.L.",
    "codiceCow": "ITS",
    "descrizioneDSL": "ITAL-TEL S.R.L."
  },
  {
    "descrizione": "ITALIACOMM SRL",
    "codiceCow": "ITO",
    "descrizioneDSL": "ITALIACOMM SRL"
  },
  {
    "descrizione": "ITALY ADSL S.R.L.",
    "codiceCow": "IAS",
    "descrizioneDSL": "ITALY ADSL S.R.L."
  },
  {
    "descrizione": "ITELS�� S.R.L.",
    "codiceCow": "TIG",
    "descrizioneDSL": "ITELS�� S.R.L."
  },
  {
    "descrizione": "KONVERTO S.P.A.",
    "codiceCow": "RUN",
    "descrizioneDSL": "KONVERTO S.P.A."
  },
  {
    "descrizione": "KPNQWEST ITALIA S.P.A.",
    "codiceCow": "KPI",
    "descrizioneDSL": "KPNQWEST ITALIA S.P.A."
  },
  {
    "descrizione": "LEONET S.R.L.",
    "codiceCow": "LEO",
    "descrizioneDSL": "LEONET S.R.L."
  },
  {
    "descrizione": "LIVECOM S.C.S.",
    "codiceCow": "CAD",
    "descrizioneDSL": "LIVECOM S.C.S."
  },
  {
    "descrizione": "MACROTEL ITALIA S.R.L.",
    "codiceCow": "MAE",
    "descrizioneDSL": "MACROTEL ITALIA S.R.L."
  },
  {
    "descrizione": "MANDARIN WIMAX SICILIA SPA",
    "codiceCow": "MAN",
    "descrizioneDSL": "MANDARIN WIMAX SICILIA SPA"
  },
  {
    "descrizione": "MARENE ON LINE S.N.C.",
    "codiceCow": "MAR",
    "descrizioneDSL": "MARENE ON LINE S.N.C."
  },
  {
    "descrizione": "MC-LINK SPA",
    "codiceCow": "MCL",
    "descrizioneDSL": "MC-LINK SPA"
  },
  {
    "descrizione": "MEDI@NET S.R.L.",
    "codiceCow": "MEH",
    "descrizioneDSL": "MEDI@NET S.R.L."
  },
  {
    "descrizione": "MEDIACARE S.P.A.",
    "codiceCow": "MEQ",
    "descrizioneDSL": "MEDIACARE S.P.A."
  },
  {
    "descrizione": "MESSAGENET S.P.A.",
    "codiceCow": "MSS",
    "descrizioneDSL": "MESSAGENET S.P.A."
  },
  {
    "descrizione": "MICSO S.R.L.",
    "codiceCow": "MCS",
    "descrizioneDSL": "MICSO S.R.L."
  },
  {
    "descrizione": "MNET S.R.L.",
    "codiceCow": "MNE",
    "descrizioneDSL": "MNET S.R.L."
  },
  {
    "descrizione": "MOMAX S.R.L.",
    "codiceCow": "MOM",
    "descrizioneDSL": "MOMAX S.R.L."
  },
  {
    "descrizione": "MPC ITALIA S.R.L.",
    "codiceCow": "MPC",
    "descrizioneDSL": "MPC ITALIA S.R.L."
  },
  {
    "descrizione": "MULTIWIRE S.R.L.",
    "codiceCow": "MUW",
    "descrizioneDSL": "MULTIWIRE S.R.L."
  },
  {
    "descrizione": "NET \u0026 COM SRL",
    "codiceCow": "GRU",
    "descrizioneDSL": "NET \u0026 COM SRL"
  },
  {
    "descrizione": "NET GLOBAL SRL",
    "codiceCow": "TDS",
    "descrizioneDSL": "NET GLOBAL SRL"
  },
  {
    "descrizione": "NETCOM S.R.L.",
    "codiceCow": "NEC",
    "descrizioneDSL": "NETCOM S.R.L."
  },
  {
    "descrizione": "NETOIP.COM S.R.L.",
    "codiceCow": "NTP",
    "descrizioneDSL": "NETOIP.COM S.R.L."
  },
  {
    "descrizione": "NEXIN TECNOLOGIES S.P.A.",
    "codiceCow": "NXN",
    "descrizioneDSL": "NEXIN TECNOLOGIES S.P.A."
  },
  {
    "descrizione": "NEXT.IT S.R.L.",
    "codiceCow": "NXI",
    "descrizioneDSL": "NEXT.IT S.R.L."
  },
  {
    "descrizione": "NEXTIP",
    "codiceCow": "NXP",
    "descrizioneDSL": "NEXTIP"
  },
  {
    "descrizione": "NGI S.P.A.",
    "codiceCow": "NGI",
    "descrizioneDSL": "NGI S.P.A."
  },
  {
    "descrizione": "NHM S.R.L.",
    "codiceCow": "NHA",
    "descrizioneDSL": "NHM S.R.L."
  },
  {
    "descrizione": "NOITEL ITALIA S.R.L.",
    "codiceCow": "NAI",
    "descrizioneDSL": "NOITEL ITALIA S.R.L."
  },
  {
    "descrizione": "OKCOM SPA",
    "codiceCow": "OKC",
    "descrizioneDSL": "OKCOM SPA"
  },
  {
    "descrizione": "OMNINETWORK S.R.L.",
    "codiceCow": "SIS",
    "descrizioneDSL": "OMNINETWORK S.R.L."
  },
  {
    "descrizione": "OMNIWAVE SRL",
    "codiceCow": "NTK",
    "descrizioneDSL": "OMNIWAVE SRL"
  },
  {
    "descrizione": "ONE CLOUD S.R.L.",
    "codiceCow": "OCL",
    "descrizioneDSL": "ONE CLOUD S.R.L."
  },
  {
    "descrizione": "OPEN SKY",
    "codiceCow": "OPE",
    "descrizioneDSL": "OPEN SKY"
  },
  {
    "descrizione": "OPTIMA ITALIA S.P.A.",
    "codiceCow": "OPT",
    "descrizioneDSL": "OPTIMA ITALIA S.P.A."
  },
  {
    "descrizione": "ORAKOM S.R.L.",
    "codiceCow": "ORK",
    "descrizioneDSL": "ORAKOM S.R.L."
  },
  {
    "descrizione": "ORANGE BUSINESS ITALY SPA",
    "codiceCow": "GBO",
    "descrizioneDSL": "ORANGE BUSINESS ITALY SPA"
  },
  {
    "descrizione": "PANSERVICE SAS DI CUSEO F. \u0026 C.",
    "codiceCow": "PAN",
    "descrizioneDSL": "PANSERVICE SAS DI CUSEO F. \u0026 C."
  },
  {
    "descrizione": "PARLA.IT S.P.A.",
    "codiceCow": "PAR",
    "descrizioneDSL": "PARLA.IT S.P.A."
  },
  {
    "descrizione": "PENTACOM S.R.L.",
    "codiceCow": "PEN",
    "descrizioneDSL": "PENTACOM S.R.L."
  },
  {
    "descrizione": "PLANETEL SRL",
    "codiceCow": "PLT",
    "descrizioneDSL": "PLANETEL SRL"
  },
  {
    "descrizione": "POSITIVO S.R.L.",
    "codiceCow": "PSV",
    "descrizioneDSL": "POSITIVO S.R.L."
  },
  {
    "descrizione": "PROFESSIONAL LINK S.R.L.",
    "codiceCow": "PFL",
    "descrizioneDSL": "PROFESSIONAL LINK S.R.L."
  },
  {
    "descrizione": "PUBLICOM SPA",
    "codiceCow": "ALT",
    "descrizioneDSL": "PUBLICOM SPA"
  },
  {
    "descrizione": "QCOM SPA",
    "codiceCow": "ORO",
    "descrizioneDSL": "QCOM SPA"
  },
  {
    "descrizione": "RAIFFEISEN ONLINE SOC. COOP.",
    "codiceCow": "RAF",
    "descrizioneDSL": "RAIFFEISEN ONLINE SOC. COOP."
  },
  {
    "descrizione": "RS ADVANCED SYSTEM S.R.L.",
    "codiceCow": "RSA",
    "descrizioneDSL": "RS ADVANCED SYSTEM S.R.L."
  },
  {
    "descrizione": "S.P.E.  S.A.S.",
    "codiceCow": "SPE",
    "descrizioneDSL": "S.P.E.  S.A.S."
  },
  {
    "descrizione": "SATCOM S.P.A.",
    "codiceCow": "SAT",
    "descrizioneDSL": "SATCOM S.P.A."
  },
  {
    "descrizione": "SEA - EDP SAS",
    "codiceCow": "SEA",
    "descrizioneDSL": "SEA - EDP SAS"
  },
  {
    "descrizione": "SELENE S.P.A.",
    "codiceCow": "SLN",
    "descrizioneDSL": "SELENE S.P.A."
  },
  {
    "descrizione": "SI.TEK INFORMATICA S.R.L.",
    "codiceCow": "ITK",
    "descrizioneDSL": "SI.TEK INFORMATICA S.R.L."
  },
  {
    "descrizione": "SIPNET SPA",
    "codiceCow": "SIP",
    "descrizioneDSL": "SIPNET SPA"
  },
  {
    "descrizione": "SIPORTAL S.R.L.",
    "codiceCow": "SPO",
    "descrizioneDSL": "SIPORTAL S.R.L."
  },
  {
    "descrizione": "SIRIUS TECHNOLOGY SRL",
    "codiceCow": "SPX",
    "descrizioneDSL": "SIRIUS TECHNOLOGY SRL"
  },
  {
    "descrizione": "SISTEMI UNO S.R.L.",
    "codiceCow": "SI1",
    "descrizioneDSL": "SISTEMI UNO S.R.L."
  },
  {
    "descrizione": "SIX COMM S.R.L.",
    "codiceCow": "SXO",
    "descrizioneDSL": "SIX COMM S.R.L."
  },
  {
    "descrizione": "SKY ITALIA S.R.L.",
    "codiceCow": "KYT",
    "descrizioneDSL": "SKY ITALIA S.R.L."
  },
  {
    "descrizione": "SKY-LINK S.R.L.S.",
    "codiceCow": "LSK",
    "descrizioneDSL": "SKY-LINK S.R.L.S."
  },
  {
    "descrizione": "SKYWEBTV SRL",
    "codiceCow": "MPA",
    "descrizioneDSL": "SKYWEBTV SRL"
  },
  {
    "descrizione": "SPIN SRL",
    "codiceCow": "SPI",
    "descrizioneDSL": "SPIN SRL"
  },
  {
    "descrizione": "ST SRL",
    "codiceCow": "STS",
    "descrizioneDSL": "ST SRL"
  },
  {
    "descrizione": "SUNFLOWER COMMUNICATION RESEARCH \u0026 TECHNOLOGY S.R.L.",
    "codiceCow": "SPV",
    "descrizioneDSL": "SUNFLOWER COMMUNICATION RESEARCH \u0026 TECHNOLOGY S.R.L."
  },
  {
    "descrizione": "T-SYSTEMS ITALIA S.P.A.",
    "codiceCow": "TSY",
    "descrizioneDSL": "T-SYSTEMS ITALIA S.P.A."
  },
  {
    "descrizione": "T.NET S.P.A.",
    "codiceCow": "TNE",
    "descrizioneDSL": "T.NET S.P.A."
  },
  {
    "descrizione": "TEANET SERVIZI S.R.L.",
    "codiceCow": "TEA",
    "descrizioneDSL": "TEANET SERVIZI S.R.L."
  },
  {
    "descrizione": "TECHLABITALIA S.R.L.",
    "codiceCow": "ECH",
    "descrizioneDSL": "TECHLABITALIA S.R.L."
  },
  {
    "descrizione": "TECNO GENERAL S.R.L.",
    "codiceCow": "TOG",
    "descrizioneDSL": "TECNO GENERAL S.R.L."
  },
  {
    "descrizione": "TECNOTEL SERVIZI TECNOLOGICI S.R.L.",
    "codiceCow": "NTH",
    "descrizioneDSL": "TECNOTEL SERVIZI TECNOLOGICI S.R.L."
  },
  {
    "descrizione": "TELEMAR S.P.A.",
    "codiceCow": "TEM",
    "descrizioneDSL": "TELEMAR S.P.A."
  },
  {
    "descrizione": "TELEMEDIA.NET SRL",
    "codiceCow": "TMN",
    "descrizioneDSL": "TELEMEDIA.NET SRL"
  },
  {
    "descrizione": "TELEUNIT S.P.A.",
    "codiceCow": "TLH",
    "descrizioneDSL": "TELEUNIT S.P.A."
  },
  {
    "descrizione": "TELEVIDEOCOM S.R.L.",
    "codiceCow": "TVD",
    "descrizioneDSL": "TELEVIDEOCOM S.R.L."
  },
  {
    "descrizione": "TELMEKOM S.R.L.",
    "codiceCow": "ELO",
    "descrizioneDSL": "TELMEKOM S.R.L."
  },
  {
    "descrizione": "TERRECABLATE RETI E SERVIZI SRL",
    "codiceCow": "CTB",
    "descrizioneDSL": "TERRECABLATE RETI E SERVIZI SRL"
  },
  {
    "descrizione": "TEX97 SPA",
    "codiceCow": "TE9",
    "descrizioneDSL": "TEX97 SPA"
  },
  {
    "descrizione": "TIME-NET SRL",
    "codiceCow": "TEN",
    "descrizioneDSL": "TIME-NET SRL"
  },
  {
    "descrizione": "TOPNET TELECOMUNICAZIONI SRL",
    "codiceCow": "TOP",
    "descrizioneDSL": "TOPNET TELECOMUNICAZIONI SRL"
  },
  {
    "descrizione": "TRIVENET SPA",
    "codiceCow": "TRI",
    "descrizioneDSL": "TRIVENET SPA"
  },
  {
    "descrizione": "TWT S.P.A.",
    "codiceCow": "TWT",
    "descrizioneDSL": "TWT S.P.A."
  },
  {
    "descrizione": "UMBRIA.NET S.R.L.",
    "codiceCow": "UMB",
    "descrizioneDSL": "UMBRIA.NET S.R.L."
  },
  {
    "descrizione": "UNICA TELECOMUNICAZIONI S.R.L.",
    "codiceCow": "UNC",
    "descrizioneDSL": "UNICA TELECOMUNICAZIONI S.R.L."
  },
  {
    "descrizione": "UNIDATA",
    "codiceCow": "UNI",
    "descrizioneDSL": "UNIDATA"
  },
  {
    "descrizione": "UNO COMMUNICATIONS S.P.A.",
    "codiceCow": "UNO",
    "descrizioneDSL": "UNO COMMUNICATIONS S.P.A."
  },
  {
    "descrizione": "UTILITY LINE ITALIA SRL",
    "codiceCow": "ULI",
    "descrizioneDSL": "UTILITY LINE ITALIA SRL"
  },
  {
    "descrizione": "VANCO S.R.L.",
    "codiceCow": "VAN",
    "descrizioneDSL": "VANCO S.R.L."
  },
  {
    "descrizione": "VERIZON ITALIA S.P.A.",
    "codiceCow": "WLC",
    "descrizioneDSL": "VERIZON ITALIA S.P.A."
  },
  {
    "descrizione": "VOX TEL S.R.L.",
    "codiceCow": "VOT",
    "descrizioneDSL": "VOX TEL S.R.L."
  },
  {
    "descrizione": "VOXBONE S.A.",
    "codiceCow": "VOB",
    "descrizioneDSL": "VOXBONE S.A."
  },
  {
    "descrizione": "WARIAN S.A.S. DI ALFREDO GIORDANO \u0026 C.",
    "codiceCow": "WAR",
    "descrizioneDSL": "WARIAN S.A.S. DI ALFREDO GIORDANO \u0026 C."
  },
  {
    "descrizione": "WEBCOM TLC SRL",
    "codiceCow": "WEB",
    "descrizioneDSL": "WEBCOM TLC SRL"
  },
  {
    "descrizione": "WELCOME ITALIA",
    "codiceCow": "WLI",
    "descrizioneDSL": "WELCOME ITALIA"
  },
  {
    "descrizione": "WI NET SNC",
    "codiceCow": "WNT",
    "descrizioneDSL": "WI NET SNC"
  },
  {
    "descrizione": "WICITY S.R.L.",
    "codiceCow": "WII",
    "descrizioneDSL": "WICITY S.R.L."
  },
  {
    "descrizione": "WIFIWEB S.R.L.",
    "codiceCow": "WFW",
    "descrizioneDSL": "WIFIWEB S.R.L."
  },
  {
    "descrizione": "WIMORE S.R.L.",
    "codiceCow": "WIO",
    "descrizioneDSL": "WIMORE S.R.L."
  },
  {
    "descrizione": "WT S.R.L.S.",
    "codiceCow": "WCH",
    "descrizioneDSL": "WT S.R.L.S."
  },
  {
    "descrizione": "XMATICA S.R.L. SOCIETA UNIPERSONALE",
    "codiceCow": "XMA",
    "descrizioneDSL": "XMATICA S.R.L. SOCIETA UNIPERSONALE"
  },
  {
    "descrizione": "XSTREAM S.R.L.",
    "codiceCow": "XST",
    "descrizioneDSL": "XSTREAM S.R.L."
  }
]
