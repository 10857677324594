import { TOGGLE_ESITO_NEGATIVO } from '../actions/esitoNegativoToggle';

const initialState = {open: false, coverage: null}

export const esitoNegativoReducer = (state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_ESITO_NEGATIVO:
      return {...state, open: action.boolean, coverage: action.data};
    default:
      return state;
  }
}