import { Operatori } from "../../../assets/Operatori";

export const initialState = {
  comune: {
    Comune: '',
    Sigla: '',
    CODICE_COMUNE: '',
    elencoComuni: [],
    spinnerOn: false,
    autocompleteOpen: false
  },
  cap: {
    Cap: '',
    elencoCap: [],
    spinnerOn: false,
    autocompleteOpen: false
  },
  indirizzo: {
    Indirizzo: '',
    ParticellaToponomastica: '',
    elencoIndirizzi: [],
    spinnerOn: false,
    autocompleteOpen: false
  },
  civico: {
    Civico: '',
    elencoCivici: [],
    spinnerOn: false,
    autocompleteOpen: false,
    egon: ''
  },
  operatore: {
    Operatore: '',
    elencoOperatori: [
      {
        descrizione: "Non ho una linea attiva",
        codiceCow: null,
        descrizioneDSL: null
      },
      ...Operatori
    ],
    elencoOperatoriFiltrati: [{
      descrizione: "Non ho una linea attiva",
      codiceCow: null,
      descrizioneDSL: null
    }, ...Operatori],
    spinnerOn: false,
    autocompleteOpen: false
  }
};