import { SET_SELECTED_INVOICE_TO_PAY } from "../actions/areaPersonale";

const initialState = {
  selectedInvoiceToPay: null
};

export const areaPersonale = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTED_INVOICE_TO_PAY: {
      return {
        ...state, selectedInvoiceToPay: payload
      }
    }
    default: return { ...state }
  }
};