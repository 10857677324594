import { applyMiddleware, combineReducers, createStore } from "redux";
import auth from './reducers/auth';
import general from './reducers/general';
import { privacy_url } from './reducers/privacy';
import { esitoNegativoReducer } from './reducers/esitoNegativoToggle';
import { activationSteps } from './reducers/activationSteps';
import { datiWorkflow } from "./reducers/activationSteps";
import { campaign } from './reducers/campaign';
import { offerte } from './reducers/commercialOffers';
import { selectedOffer } from "./reducers/commercialOffers";
import { vas } from './reducers/vas';
import { coverage } from './reducers/coverage';
import { areaPersonale } from './reducers/areaPersonale';
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  auth,
  general,
  privacy_url,
  esitoNegativoReducer,
  activationSteps,
  datiWorkflow,
  campaign,
  offerte,
  selectedOffer,
  vas,
  coverage,
  areaPersonale
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
