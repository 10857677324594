import {
    TOGGLE_LOADING,
    TOGGLE_MENU
} from "../actions/general";

import {toast} from "react-toastify";

export const toggleSpinner = (force) => {
    return {
        type: TOGGLE_LOADING,
        force
    }
};

export const toggleMenu = () => {
    return {
        type: TOGGLE_MENU
    }
};

export const toggleToast = (tipo, messaggio) => {
    return dispatch => {
        tipo === 's' ?
            toast.success(messaggio, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
            }) : toast.error(messaggio, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
    }
};
