import { SET_SELECTED_VAS, SET_VAS_LIST } from '../actions/vas';


const initialState = {
  vas: [],
  selectedVas: []
};

export const vas = (state = initialState, action) => {
  switch (action.type) {
    case SET_VAS_LIST:
      return { ...state, vas: action.payload };
    case SET_SELECTED_VAS:
      return { ...state, selectedVas: action.payload };
    default: return { ...state };
  }
};