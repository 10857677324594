export const toponymicParticles = [
"AEROPORTO",
"ALLEE",
"ALVEO",
"ALZAIA",
"ANDRONA",
"ANGIPORTO",
"ARCHIVOLTO",
"ARCO",
"ARGINE",
"ARZERE",
"AUTOSTRADA",
"AVENUE",
"BACINO",
"BAGLIO",
"BALUARDO",
"BANCHINA",
"BELVEDERE",
"BIVIO",
"BORGATA",
"BORGHETTO",
"BORGO",
"BOULEVARD",
"CA'",
"CALA",
"CALATA",
"CALLE",
"CAMPIELLO",
"CAMPO",
"CANTO",
"CANTONE",
"CAPO",
"CARRARA",
"CASA",
"CASALE",
"CASALI",
"CASCINA",
"CASCINE",
"CASE",
"CASE SPARSE",
"CAVONE",
"CENTRO",
"CENTRO ABITATO",
"CHIASSO",
"CIRCONVALLAZIONE",
"CIRCUMVALLAZIONE",
"CLAUSTRO",
"CLIVO",
"COL",
"COLLE",
"CONDOMINIO",
"CONTRA'",
"CONTRADA",
"CORSETTO",
"CORSIA",
"CORSO",
"CORT",
"CORTE",
"CORTICELLA",
"CORTILE",
"COSTA",
"COSTARELLA",
"CROSA",
"CROSINO",
"CUPA",
"DARSENA",
"DIGA",
"DIRAMAZIONE",
"DISCESA",
"DISTACCO",
"EMICICLO",
"ERTA",
"FATTORIA",
"FONDACO",
"FONDAMENTA",
"FONDO",
"FORO",
"FORTE",
"FOSSO",
"FRAZIONE",
"GALLERIA",
"GIARDINI",
"GIARDINO",
"GRADINATA",
"GRADINI",
"GRADONI",
"HAMEAU",
"II TRAVERSA",
"INCROCIO",
"INTERRATO",
"ISOLA",
"ISOLATO",
"LARGHETTO",
"LARGO",
"LOCALITA",
"LOCALITA'",
"LOGGE",
"LOGGIA",
"LUNGADIGE",
"LUNGARGINE",
"LUNGARNO",
"LUNGO",
"LUNGO ADDA",
"LUNGO BRENTA",
"LUNGO DORA",
"LUNGO ISARCO",
"LUNGO LAGO",
"LUNGO MALLERO",
"LUNGO PO",
"LUNGO STURA",
"LUNGO TALVERA",
"LUNGO TANARO",
"LUNGOBISAGNO",
"LUNGOCRATI",
"LUNGOLAGO",
"LUNGOLARIO",
"LUNGOMARE",
"LUNGOSABATO",
"LUNGOSTURA",
"LUNGOTANARO",
"LUNGOTEVERE",
"MASO",
"MASSERIA",
"MOLINO",
"MOLO",
"MULATTIERA",
"MURA",
"NUCLEO",
"PARCO",
"PASSAGGIO",
"PASSEGGIATA",
"PASSETTO",
"PASSO",
"PENDICE",
"PIAGGIA",
"PIANO",
"PIAZ",
"PIAZZA",
"PIAZZALE",
"PIAZZETTA",
"PLACE",
"PODERE",
"POGGIO",
"PONTILE",
"PORTICCIOLO",
"PORTICO",
"PORTO",
"PROLUNGAMENTO",
"PROVINCIALE",
"PUNTA",
"QUADRATO",
"QUADRIVIO",
"QUARTIERE",
"RAMO",
"RAMO PRIMO",
"RAMO SECONDO",
"RAMPA",
"RAMPE",
"RATTO",
"RECINTO",
"REGIONE",
"RIO",
"RIO TERA'",
"RIO TERRA'",
"RIONE",
"RIPA",
"RIVA",
"RIVIERA",
"RONCO",
"RONDO'",
"ROTONDA",
"ROUTE",
"RUA",
"RUE",
"SALITA",
"SALIZADA",
"SCALA",
"SCALE",
"SCALEA",
"SCALI",
"SCALINATA",
"SCALO",
"SDRUCCIOLO",
"SENTIERO",
"SESTIERE",
"SEZIONE",
"SITO",
"SOBBORGO",
"SOTTOPASSAGGI",
"SOTTOPASSAGGIO",
"SOTTOPORTICO",
"SOTTOVIA",
"SPALTO",
"SPIANATA",
"STAZZO",
"STR.",
"STRADA",
"STRADA COMUNALE",
"STRADA PRIVATA",
"STRADA PROVINCIALE",
"STRADA STATALE",
"STRADA VICINALE",
"STRADALE",
"STRADELLA",
"STRADELLO",
"STRADONE",
"STREDA",
"STRETTA",
"STRETTOIA",
"SUBBORGO",
"SUPERSTRADA",
"SUPPORTICO",
"TANGENZIALE",
"TENUTA",
"TERRA",
"TERRAZZA",
"TORRE",
"TORRENTE",
"TRAVERSA",
"TRESANDA",
"TRONCO",
"VALLE",
"VALLONE",
"VARCO",
"VIA",
"VIADOTTO",
"VIAL",
"VIALE",
"VIALETTO",
"VICINALE",
"VICO",
"VICOLETTO",
"VICOLO",
"VICOLO CHIUSO",
"VICOLO CIECO",
"VICOLO LUNGO",
"VICOLO STRETTO",
"VIELLA",
"VIETTA",
"VILLA",
"VILLAGGIO",
"VILLETTA",
"VIOTTOLO",
"VIUZZA",
"VIUZZO",
"VOCABOLO",
"VOLTA",
"VOLTO",
"VOLTONE",
"ZONA",
];