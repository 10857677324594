import { GET_OFFERTE, SET_SELECTED_OFFER } from '../actions/commercialOffers';

const initialSelectedOffer = {
  id: 2,
  name: "SpeedPower Promo Start",
  head_name: "Speed Power",
  sub_name: "Promo Start",
  valid_to: "2021-05-31T21:59:59.000000Z",
  default: true,
  contract_duration_months: 24,
  trial_days: 0,
  discount_months: 12,
  discount_amount: 24.9,
  monthly_amount: 29.9,
  activation_amount: 100,
  discount_activation_amount: 0,
  standard_amount: 29.9,
  lines: [
    "Tariffa promozionale valida per tutto il primo anno.",
    "Dal 13° mese, il canone mensile sarà di 29,90 €.",
    "Tutti i costi indicati sono da intendersi IVA inclusa"
  ],
  created_at: "2020-12-11T20:39:46.000000Z",
  updated_at: "2021-04-06T15:04:02.000000Z",
  isMock: true
}

export const offerte = (state = [], action) => {
  switch (action.type) {
    case GET_OFFERTE:
      return action.payload;
    default:
      return state
  }
}

export const selectedOffer = (state = initialSelectedOffer, action) => {
  switch (action.type) {
    case SET_SELECTED_OFFER:
      return action.payload;
    default:
      return state
  }
}