import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modale from '../Modale/Modale';
import ContactForm from '../HomeContactSection/ContactForm/ContactForm';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import chatButton from '../../assets/img/chat_button.png';
import chattaConNoi from '../../assets/img/chatta_con_noi.png';
import tiChiamoGratis from '../../assets/img/ti_chiamo_gratis.png';
import './ChatBotMobile.scss';

function ChatBotMobile() {
  const history = useHistory();
  const [viewSupportChoice, setViewSupportChoice] = useState(false);
  const [viewChatFrame, setViewChatFrame] = useState(false);
  const [hiddenContactModal, setHiddenContactModal] = useState(true); // Modale con contact form

  function viewSupport(bool) {
    setViewSupportChoice(bool);
    setViewChatFrame(!bool)
  }

  function viewContactForm(bool) {
    setViewSupportChoice(bool);
    setHiddenContactModal(bool)
  }

  return (
    <div className="chatbot-mobile-container">
      <div className={classNames("support-mobile-choice-container pt-3", { "show": viewSupportChoice })}>
        <i className="fa fa-times close-support-mobile-choice" onClick={() => setViewSupportChoice(false)} />
        <img src={tiChiamoGratis} className="mb-2 cursor-pointer" alt="Ti chiamo gratis"
          onClick={() => {
            viewContactForm(false);
            ReactGA.event({
              category: 'Button',
              action: "Ti chiamo gratis (ChatBot Mobile)",
              label: history.location.pathname
            });
          }} />
        <img src={chattaConNoi} className="cursor-pointer" alt="Chatta con noi"
          onClick={() => {
            viewSupport(false);
            ReactGA.event({
              category: 'Button',
              action: "Chatta con noi (ChatBot Mobile)",
              label: history.location.pathname
            })
          }} />
      </div>

      <div className={classNames("chatframe-mobile-container", { "show": viewChatFrame })}>
        <i className="fa fa-times close-chatframe-mobile" onClick={() => setViewChatFrame(false)} />
        <iframe src={process.env.REACT_APP_CHATBOT} allow="geolocation" title="Chat Frame" id="chat-frame-mobile" frameBorder="0" />
      </div>

      <div className="chatbot-mobile-icon-container" onClick={() => viewSupport(true)}>
        <img src={chatButton} alt="Chat Button" />
        {/* <i className="fas fa-headset" /> */}
      </div>

      {/* MODALE CONTACT FORM */}
      <Modale
        hidden={hiddenContactModal}
        bgColor='bg-linkem-blue'
        dismissButtonClass='absolute-close-button'
        displayDismissButton={true}
        setToggleModale={() => setHiddenContactModal(true)}>
        <ContactForm isModale={true} bgColor='bg-linkem-blue' textColor="text-white" />
      </Modale>
    </div>
  )
}

export default ChatBotMobile
