import React from 'react';
import { getRoutes } from "../../routing/utils";
import './Layout.scss';

export default function Layout({ user }) {
    return (
        <div className="layout-wrapper">
            {getRoutes(user)}
        </div>
    );
}
