import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toggleSpinner, toggleToast } from '../../store/actionCreators/general';
import { toggleEsitoNegativo } from '../../store/actionCreators/esitoNegativoToggle';
import { updateDatiContatto } from '../../store/actionCreators/activationSteps';
import ChatBotMobile from '../ChatBotMobile/ChatBotMobile';
import Autocomplete from '../Autocomplete/Autocomplete';
import BootstrapSpinner from '../BootstrapSpinner/BootstrapSpinner';
import Modale from '../Modale/Modale';
import AddressNotFound from "../AddressNotFound/AddressNotFound";
import { debounce } from 'lodash';
import { removeToponomastic } from '../utils/utils';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import './VerificaCopertura.scss';
import { useDispatch } from 'react-redux';
import { HeaderModaleVerificaCopertura } from './components/components';
import { getIndirizzo, getCivico, getCopertura, getComune } from '../../api/verificaCopertura/verificaCopertura';
import { initialState } from './state/state';
import ButtonLinkem from '../ButtonLinkem/ButtonLinkem';

function VerificaCopertura({ columnMode, setToggleModale }) {

  const dispatch = useDispatch();
  const history = useHistory();

  // Tracking dello scrolling per view chatbot
  const [scrolled, setScrolled] = useState(false);

  // Quando scrollo la prima volta setto il booleano per visualizzare l'icona del chatbot
  useEffect(() => {
    window.addEventListener("scroll", () => { if (window.scrollY !== 0) setScrolled(true) });
  }, [scrolled]);

  // Ref per ogni input per determinare il posizionamento dell'autocomplete
  const inputComune = React.useRef();
  const inputIndirizzo = React.useRef();
  const inputCivico = React.useRef();
  const inputOperatore = React.useRef();

  // Ref per ogni autocomplete
  const divComune = React.useRef();
  const divIndirizzo = React.useRef();
  const divCivico = React.useRef();
  const divOperatore = React.useRef();

  // Info per Styling dell'autocomplete di ogni Input Ref
  const [autoCompleteComune, setAutoCompleteComune] = useState({});
  const [autoCompleteIndirizzo, setAutoCompleteIndirizzo] = useState({});
  const [autoCompleteCivico, setAutoCompleteCivico] = useState({});
  const [autoCompleteOperatore, setAutoCompleteOperatore] = useState({});

  const [active_line, setActiveLine] = useState({ value: '', label: '' });
  // const [operatorModal, setOperatorModal] = useState(true);

  // Gestione apertura/chiusura campi in modalità mobile
  const [verificaOpenMobile, setVerificaOpenMobile] = useState(false);

  const [showVerificaCopertura, ] = useState(true);

  // Se clicco al di fuori di dei div con gli elenchi ne gestisco la chiusura
  function handleClick(e) {
    if (
      divComune?.current && inputComune?.current &&
      !divComune?.current.contains(e.target) &&
      !inputComune?.current.contains(e.target) &&
      (divComune?.current.firstChild.clientHeight !== 0)) {
      handleCloseComune();
    }
    if (
      divIndirizzo?.current && inputIndirizzo?.current &&
      !divIndirizzo?.current.contains(e.target) &&
      !inputIndirizzo?.current.contains(e.target) &&
      (divIndirizzo?.current.firstChild.clientHeight !== 0)) {
      setIndirizzo(prevState => ({
        ...prevState,
        Indirizzo: '',
        autocompleteOpen: false
      }));
      setCivico(prevState => ({
        ...prevState,
        Civico: ''
      }));
    }
    if (
      divCivico?.current && inputCivico?.current &&
      !divCivico?.current.contains(e.target) &&
      !inputCivico?.current.contains(e.target) &&
      (divCivico?.current.firstChild.clientHeight !== 0)) {
      handleCloseCivico();
    }
    if (
      divOperatore?.current && inputOperatore?.current &&
      !divOperatore?.current.contains(e.target) &&
      !inputOperatore?.current.contains(e.target) &&
      (divOperatore?.current.firstChild.clientHeight !== 0)) {
      setOperatore(prevState => ({
        ...prevState,
        Operatore: '',
        autocompleteOpen: false
      }))
    }
  };

  // Aggiungo un eventListener per reperire se i click si trovano al di fuori dei div
  useEffect(() => {
    document.addEventListener('click', handleClick);
  }, []); // eslint-disable-line

  // Dati del form
  const [comune, setComune] = useState(initialState.comune);
  const [cap, setCap] = useState(initialState.cap);
  const [indirizzo, setIndirizzo] = useState(initialState.indirizzo);
  const [civico, setCivico] = useState(initialState.civico);
  const [operatore, setOperatore] = useState(initialState.operatore);

  // Boolean per gestire l'apertura della modale
  const [ShowAddressNotFoundModal, setShowAddressNotFoundModal] = useState(false);

  // ------------------------
  //   CHIAMATE AL BACKEND
  // ------------------------
  function fetchComune(comune) {
    setComune(prevState => ({
      ...prevState,
      spinnerOn: true
    })) // attivazione spinner
    getComune({ search: comune }).then(res => {
      // salvataggio elenco da BE, disattivazione spinner ed apertura div autocomplete
      setComune(prevState => ({
        ...prevState,
        elencoComuni: res.data.risultato,
        spinnerOn: false,
        autocompleteOpen: true
      }))
    }).catch(() => {
      setComune(prevState => ({
        ...prevState,
        spinnerOn: false
      }))
    })
  };

  function fetchIndirizzo(body) {
    setIndirizzo(prevState => ({
      ...prevState,
      spinnerOn: true
    }));
    getIndirizzo(body).then(res => {
      setIndirizzo(prevState => ({
        ...prevState,
        elencoIndirizzi: res.data.risultato,
        spinnerOn: false,
        autocompleteOpen: true
      }))
    }).catch(() => {
      setIndirizzo(prevState => ({
        ...prevState,
        spinnerOn: false
      }));
    })
  };

  function fetchCivico(body) {
    setCivico(prevState => ({
      ...prevState,
      spinnerOn: true
    }));
    getCivico(body).then(res => {
      setCivico(prevState => ({
        ...prevState,
        elencoCivici: res.data.risultato,
        spinnerOn: false,
        autocompleteOpen: true
      }));
    }).catch(() => {
      setCivico(prevState => ({
        ...prevState,
        spinnerOn: false
      }));
    })
  };

  function checkCopertura(body) {
    dispatch(toggleSpinner())
    getCopertura(body).then(res => {
      dispatch(toggleSpinner());
      if (columnMode) {
        setToggleModale()
      }
      // Se la verifica va a buon fine si reindirizza all'apposita pagina
      if (res.data.ESITO === 'OK') {
        dispatch(toggleEsitoNegativo(false))
      } else { // Se la verifica dà esito negativo si apre la relativa barra
        dispatch(toggleEsitoNegativo(true, { ...body, ...res.data }))
      }
      history.push({
        pathname: '/esito-verifica',
        datiRichiesta: body,
        datiVerifica: res.data,
        active_line: active_line.label === 'Ho una linea attiva'
      })
    }).catch(() => {
      dispatch(toggleToast('e', "L'indirizzo inserito non è stato trovato"));
      dispatch(toggleSpinner())
    })
  };

  // -------------------------
  //   GESTIONE CAMPI INPUT
  // -------------------------

  // si usa debounce di lodash per far partire una unica chiamata quando si smette di digitare il comune per 400ms
  const debouncedComune = useCallback(
    debounce(nextValue => fetchComune(nextValue), 800),
    []
  );

  function handleChangeComune(e) {
    setComune({
      ...comune,
      [e.target.name]: e.target.value
    })
    if (e.target.value.length > 2) {
      const { value: nextValue } = e.target;
      debouncedComune(nextValue)
    }
  };

  // si usa debounce di lodash per far partire una unica chiamata quando si smette di digitare l'indirizzo per 400ms
  const debouncedIndirizzo = useCallback(
    debounce(nextValue => fetchIndirizzo(nextValue), 800),
    []
  )

  function handleChangeIndirizzo(e) {
    setIndirizzo({
      ...indirizzo,
      [e.target.name]: e.target.value
    })
    if (e.target.value.length > 1) {
      const nextValue = {
        city: comune.Comune,
        province: comune.Sigla,
        search: removeToponomastic(e.target.value)
      }
      debouncedIndirizzo(nextValue)
    }
  };

  // si usa debounce di lodash per far partire una unica chiamata quando si smette di digitare l'indirizzo per 400ms
  const debouncedCivico = useCallback(
    debounce(nextValue => fetchCivico(nextValue), 800),
    []
  );

  function handleChangeCivico(e) {
    setCivico({
      ...civico,
      [e.target.name]: e.target.value
    });
    if (e.target.value.length > 0) {
      const nextValue = {
        city: comune.Comune,
        province: comune.Sigla,
        toponymic_particle: indirizzo.ParticellaToponomastica,
        street_name: indirizzo.Indirizzo,
        search: e.target.value
      }
      debouncedCivico(nextValue)
    }
  };

  function handleChangeOperatore(e) {
    const newOperatore = operatore.elencoOperatori.filter(o => o.descrizione.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
    setOperatore({
      ...operatore,
      [e.target.name]: e.target.value,
      elencoOperatoriFiltrati: newOperatore
    });
  };

  // Resetta i dati degli elenchi e chiude l'autocomplete se le condizioni non sono soddisfatte
  useEffect(() => {
    if (!comune.Comune || comune.Comune.length < 3) setComune(prevState => ({
      ...prevState,
      elencoComuni: [],
      autocompleteOpen: false
    }));
    if (!cap.Cap) setCap(prevState => ({
      ...prevState,
      elencoCap: [],
      autocompleteOpen: false
    }));
    if (!indirizzo.Indirizzo || indirizzo.Indirizzo.length < 3) setIndirizzo(prevState => ({
      ...prevState,
      elencoIndirizzi: [],
      autocompleteOpen: false
    }));
    if (!civico.Civico) setCivico(prevState => ({
      ...prevState,
      elencoCivici: [],
      autocompleteOpen: false
    }));
  }, [comune.Comune, cap.Cap, indirizzo.Indirizzo, civico.Civico]);

  // SUBMIT FORM
  function handleSubmit(e) {
    e.preventDefault();
    updateDatiContatto('active_line', active_line.value); // salvo i dati di active_line in redux
    const body = {
      city: comune.Comune,
      istat: comune.CODICE_COMUNE,
      province: comune.Sigla,
      // postal_code: cap.Cap,
      toponymic_particle: indirizzo.ParticellaToponomastica,
      street_name: indirizzo.Indirizzo,
      address_number: civico.Civico,
      egon: civico.egon,
      origin: "website"
    };
    ReactGA.event({
      category: 'Button',
      action: `CTA Verifica copertura per ${comune.Comune}`,
      label: history.location.pathname
    });
    checkCopertura(body);
  };

  const handleCloseComune = () => {
    setComune(prevState => ({
      ...prevState,
      Comune: '',
      autocompleteOpen: false
    }));
    setIndirizzo(prevState => ({
      ...prevState,
      Indirizzo: ''
    }));
    setCivico(prevState => ({
      ...prevState,
      Civico: ''
    }))
  };

  const handleCloseCivico = () => {
    setCivico(prevState => ({
      ...prevState,
      Civico: '',
      autocompleteOpen: false
    }))
  };

  // Gestione della chiusura dei div nel campo la ricerca non desse risultati
  function handleChiusuraDiv(nomeCampo) {
    if (nomeCampo === 'comune') handleCloseComune();
    if (nomeCampo === 'indirizzo') {
      setIndirizzo(prevState => ({
        ...prevState,
        Indirizzo: '',
        autocompleteOpen: false
      }));
    }
    if (nomeCampo === 'civico') handleCloseCivico()
  };

  const noResult = (nomeCampo) => <li onClick={() => handleChiusuraDiv(nomeCampo)}>
    La ricerca non ha prodotto risultati
  </li>;

  function selectOperatorOption(el) {
    // In caso di LA passo il nome dell'operatore, altrimenti ''
    if (el.descrizione === 'Non ho una linea attiva') {
      setActiveLine({ value: '', label: 'Non ho una linea attiva' })
    } else {
      setActiveLine({ value: el.descrizione, label: 'Ho una linea attiva' })
      //setOperatorModal(false);
    }
    setOperatore({
      ...operatore,
      Operatore: el.descrizione,
      autocompleteOpen: false
    });
  };

  // Impedisce l'uso del tasto tab per spostarsi su un altro input
  function preventTab(e) {
    if (e.keyCode === 9) e.preventDefault()
  };

  return (
    <div className="container-fluid verifica-copertura-wrapper"
      style={!columnMode ? { bottom: '0', position: 'fixed', zIndex: '1002' } : { position: 'relative' }}
    >
      {
        showVerificaCopertura ? (
          <>
            <span className={classNames({ 'd-none': !scrolled })}>
              {!columnMode && <span className="chatbot-mobile-wrapper"><ChatBotMobile /></span>}
            </span>
            <form autoComplete="off">
              <div className="verifica-copertura-container">
                {   /* Banner per gestione chiusura in mobile. Non si applica per la verifica in modale */
                  (!columnMode && verificaOpenMobile) && <div className="toggle-container" onClick={() => setVerificaOpenMobile(!verificaOpenMobile)}>
                    <i className="fas fa-chevron-down text-danger" />
                  </div>
                }

                <div className="row align-items-center justify-content-center p-3 bg-linkem-blue">
                  {columnMode && <HeaderModaleVerificaCopertura />}

                  {/* CAMPO COMUNE */}
                  <div className={`${columnMode ? 'col-sm-5 show' : 'col-md-2'} text-center px-1 input-fields-verifica ${verificaOpenMobile ? 'show' : ''}`}>
                    <input
                      type="text"
                      ref={inputComune}
                      className="form-control mb-2 p-3 border-radius-20"
                      name="Comune"
                      onKeyDown={preventTab}
                      onChange={handleChangeComune}
                      value={comune.Comune}
                      placeholder="Comune"
                      onFocus={() => setAutoCompleteComune({
                        width: inputComune.current.getBoundingClientRect().width - 31,
                        left: 18,
                        bottom: columnMode ? null : inputComune.current.getBoundingClientRect().height + 9,
                        top: !columnMode ? null : inputComune.current.getBoundingClientRect().height + 1
                      })}
                    />

                    <BootstrapSpinner extraClass={classNames("input-field-spinner", { 'd-none': !comune.spinnerOn })} />

                    <span ref={divComune}>
                      <Autocomplete closed={!comune.autocompleteOpen} style={autoCompleteComune}>
                        {!comune.elencoComuni ? noResult('comune') :
                          React.Children.toArray(comune.elencoComuni.map(el => <li
                            onClick={() => {
                              setComune({
                                ...comune,
                                Comune: el.COMUNE,
                                Sigla: el.Sigla,
                                CODICE_COMUNE: el.CODICE_COMUNE,
                                autocompleteOpen: false
                              });
                              setIndirizzo(prevState => ({
                                ...prevState,
                                Indirizzo: ''
                              }));
                              setCivico(prevState => ({
                                ...prevState,
                                Civico: ''
                              }))
                            }}>{el.COMUNE}</li>))
                        }
                      </Autocomplete>
                    </span>
                  </div>

                  {/* CAMPO INDIRIZZO */}
                  <div className={`${columnMode ? 'col-sm-7 show' : 'col-md-4'} text-center px-1 input-fields-verifica ${verificaOpenMobile ? 'show' : ''}`}>
                    <input type="text" ref={inputIndirizzo}
                      className={classNames("form-control mb-2 p-3 border-radius-20", { "disabled-element": !comune.Comune || comune.spinnerOn || comune.Comune.length < 3 || (comune.Comune && comune.autocompleteOpen) })}
                      name='Indirizzo'
                      onKeyDown={preventTab}
                      onChange={handleChangeIndirizzo} value={indirizzo.Indirizzo}
                      placeholder="Indirizzo (senza via, viale, ecc.)"
                      onFocus={() => setAutoCompleteIndirizzo({
                        width: inputIndirizzo.current.getBoundingClientRect().width - 31,
                        left: 18,
                        bottom: columnMode ? null : inputIndirizzo.current.getBoundingClientRect().height + 9,
                        top: !columnMode ? null : inputIndirizzo.current.getBoundingClientRect().height + 1
                      })} />

                    <BootstrapSpinner
                      extraClass={classNames("input-field-spinner", { 'd-none': !indirizzo.spinnerOn })} />

                    <span ref={divIndirizzo}>
                      <Autocomplete closed={!indirizzo.autocompleteOpen} style={autoCompleteIndirizzo}>
                        {!indirizzo.elencoIndirizzi ? noResult('indirizzo') :
                          React.Children.toArray(indirizzo.elencoIndirizzi.map(el => <li
                            onClick={() => {
                              setIndirizzo({
                                ...indirizzo,
                                Indirizzo: el.Indirizzo,
                                ParticellaToponomastica: el.ParticellaToponomastica,
                                autocompleteOpen: false
                              });
                              setCivico(prevState => ({
                                ...prevState,
                                Civico: ''
                              }))
                            }}>{el.label}</li>))
                        }
                      </Autocomplete>
                    </span>
                  </div>

                  {/* CAMPO CIVICO */}
                  <div className={`${columnMode ? 'col-sm-4 show' : 'col-md-1'} text-center px-1 input-fields-verifica ${verificaOpenMobile ? 'show' : ''}`}>
                    <input type="text" ref={inputCivico}
                      className={classNames("form-control mb-2 p-3 border-radius-20", { "disabled-element": !indirizzo.Indirizzo || indirizzo.Indirizzo.length < 2 || indirizzo.spinnerOn || (indirizzo.Indirizzo && indirizzo.autocompleteOpen) })}
                      name="Civico"
                      onKeyDown={preventTab}
                      onChange={handleChangeCivico} value={civico.Civico} placeholder="Civico"
                      onFocus={() => setAutoCompleteCivico({
                        width: inputCivico.current.getBoundingClientRect().width - 31,
                        left: 18,
                        bottom: columnMode ? null : inputIndirizzo.current.getBoundingClientRect().height + 9,
                        top: !columnMode ? null : inputCivico.current.getBoundingClientRect().height + 1
                      })} />

                    <BootstrapSpinner
                      extraClass={classNames("input-field-spinner", { 'd-none': !civico.spinnerOn })} />

                    <span ref={divCivico}>
                      <Autocomplete closed={!civico.autocompleteOpen} style={autoCompleteCivico}>
                        {!civico.elencoCivici ? noResult('civico') :
                          React.Children.toArray(civico.elencoCivici.map(el => <li
                            onClick={() => setCivico({
                              ...civico,
                              Civico: el.Civico,
                              egon: el.EGON,
                              autocompleteOpen: false
                            })}>
                            {el.Civico}
                          </li>
                          ))
                        }
                      </Autocomplete>
                    </span>
                  </div>

                  {/* CAMPO OPERATORE */}
                  <div className={`${columnMode ? 'col-sm-8 show' : 'col-md-2'} text-center px-1 input-fields-verifica ${verificaOpenMobile ? 'show' : ''}`}>
                    <input type="text" ref={inputOperatore}
                      className={classNames("form-control mb-2 p-3 border-radius-20 cursor-pointer", { "disabled-element": !civico.Civico || civico.spinnerOn || (civico.Civico && civico.autocompleteOpen) })}
                      name="Operatore"
                      onKeyDown={preventTab}
                      onChange={handleChangeOperatore}
                      value={operatore.Operatore}
                      placeholder="Attuale Operatore"
                      onFocus={() => {
                        setOperatore({
                          ...operatore,
                          autocompleteOpen: true
                        })
                        setAutoCompleteOperatore({
                          width: inputOperatore.current.getBoundingClientRect().width - 31,
                          left: 18,
                          bottom: columnMode ? null : inputIndirizzo.current.getBoundingClientRect().height + 9,
                          top: !columnMode ? null : inputOperatore.current.getBoundingClientRect().height + 1
                        })
                      }} />

                    <div className={classNames("input-field-chevron cursor-pointer", { "disabled-chevron": !civico.Civico || civico.spinnerOn || (civico.Civico && civico.autocompleteOpen) })}
                      onClick={() => {
                        setOperatore({
                          ...operatore,
                          autocompleteOpen: true
                        })
                        setAutoCompleteOperatore({
                          width: inputOperatore.current.getBoundingClientRect().width - 31,
                          left: 18,
                          bottom: columnMode ? null : inputIndirizzo.current.getBoundingClientRect().height + 9,
                          top: !columnMode ? null : inputOperatore.current.getBoundingClientRect().height + 1
                        })
                      }} >
                      <i className="fas fa-chevron-down color-linkem-red" />
                    </div>

                    <span ref={divOperatore}>
                      <Autocomplete closed={!operatore.autocompleteOpen} style={autoCompleteOperatore}>
                        {!operatore.elencoOperatoriFiltrati ? noResult() :
                          React.Children.toArray(operatore.elencoOperatoriFiltrati.map(el => <li
                            onClick={() => selectOperatorOption(el)}>{el.descrizione}</li>))
                        }
                      </Autocomplete>
                    </span>
                  </div>


                  {/* SUBMIT BUTTON */}
                  <div
                    className={`${columnMode ? 'col-sm-6 order-5' : 'col-md-3 justify-content-center align-items-center'} text-center d-flex flex-column px-1`}>

                    {   /* Button per gestione apertura form in mobile. Non si applica per la verifica in modale
                     Questo button è visibile quando la form è chiusa. Una volta aperta si vede il btn che esegue la chiamata  */
                      !columnMode && <ButtonLinkem
                        polarizedRed
                        className={classNames("btn-open-mobile responsive-btn-font mt-2 mx-auto text-uppercase border-radius-20", { 'd-none': verificaOpenMobile })}
                        onClick={e => { e.preventDefault(); setVerificaOpenMobile(!verificaOpenMobile) }}>
                        <i className="fas fa-map-marker-alt mr-2" />
                        Verifica Copertura
                      </ButtonLinkem>
                    }

                    <ButtonLinkem
                      polarizedRed
                      onClick={handleSubmit}
                      className={classNames("btn-verifica responsive-btn-font mt-2 mx-auto text-uppercase border-radius-20",
                        {
                          "disabled-element": !civico.Civico || (civico.Civico && civico.autocompleteOpen),
                          "show": columnMode || (!columnMode && verificaOpenMobile)
                        })}>
                      <i className="fas fa-map-marker-alt mr-2" />
                      Verifica Copertura
                    </ButtonLinkem>

                    <div
                      className='color-bianco cursor-pointer responsive-not-found'
                      onClick={() => {
                        ReactGA.event({
                          category: 'Button',
                          action: 'No Indirizzo',
                          label: history.location.pathname
                        });
                        setShowAddressNotFoundModal(true)
                      }}
                      style={{ fontSize: '13px' }}>
                      <u>Se non trovi il tuo indirizzo clicca qui</u>
                    </div>
                  </div>

                </div>
              </div>
            </form>

            <Modale
              hidden={!ShowAddressNotFoundModal}
              displayDismissButton={true}
              bgColor='bg-linkem-blue'
              dismissButtonClass='absolute-close-button'
              setToggleModale={() => setShowAddressNotFoundModal(false)}>
              <AddressNotFound
                dati={{
                  comune: comune.Comune,
                  provincia: comune.Sigla,
                  toponimo: indirizzo.ParticellaToponomastica,
                  cap: cap.Cap,
                  indirizzo: indirizzo.Indirizzo,
                  civico: civico.Civico
                }}
                dismiss={() => setShowAddressNotFoundModal(false)} />
            </Modale>
          </>
        ) : <div
          className="verifica-copertura-container bg-linkem-blue text-white"
          style={{ height: '75px', padding: '2em', textAlign: 'center' }}
        >
          Stiamo lavorando per voi. Il sistema di verifica copertura verrà ripristinato al più presto.
          Ci scusiamo per il disagio
        </div>
      }
    </div>
  )
};

VerificaCopertura.propTypes = {
  columnMode: PropTypes.bool,
  setToggleModale: PropTypes.func,
  toggleToast: PropTypes.func,
  toggleSpinner: PropTypes.func,
  updateDatiContatto: PropTypes.func,
  toggleEsitoNegativo: PropTypes.func
};

export default VerificaCopertura;
