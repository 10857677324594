import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Modale.scss';

function Modale({
  children, hidden, setToggleModale, left, center,
  right, displayDismissButton, bgColor, dismissButtonClass
}) {

  return (
    <div className={classNames("modale-wrapper", { "d-none": hidden })}>
      {/* DIV SERVE SOLO A CHIUDERE LA MODALE CON UN CLICK-OUT */}
      <div className="outside-click" onClick={setToggleModale} />

      <div className={`modale-container border-radius-10 ${bgColor ? bgColor : ''}`}>
        {
          displayDismissButton &&
          <div
            className={`cancel-container cursor-pointer ${dismissButtonClass ? dismissButtonClass : ''}`}
            onClick={setToggleModale}
          >
            <i className="fas fa-times" />
          </div>
        }
        <div className={classNames("content-container", {
          'align-items-start': left,
          'align-items-center': center,
          'align-items-end': right
        })}
        >
          {children}
        </div>
      </div>
    </div>
  )
};

Modale.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
  setToggleModale: PropTypes.func,
  left: PropTypes.any,
  center: PropTypes.any,
  right: PropTypes.any,
  displayDismissButton: PropTypes.bool,
  bgColor: PropTypes.any,
  dismissButtonClass: PropTypes.any
};

export default Modale
