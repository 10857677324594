import { SAVE_DATI_CONTATTO, UPDATE_CURRENT_STEP, SET_ACTIVATION_TYPE, SAVE_DATI_CONFIGURAZIONE, SAVE_DATI_CGC, RESET_DATI_WORKFLOW, UPDATE_DATI_CONTATTO, UPDATE_DATI_CONFIGURAZIONE } from '../actions/activationSteps';

export const updateCurrentStep = step => ({
  type: UPDATE_CURRENT_STEP,
  step
})

export const setActivationType = (activationType, activationState) => ({
  type: SET_ACTIVATION_TYPE,
  activationType,
  activationState
})

export const saveDatiContatto = datiContatto => ({
  type: SAVE_DATI_CONTATTO,
  datiContatto
})

export const updateDatiContatto = (objKey, value) => ({
  type: UPDATE_DATI_CONTATTO,
  objKey,
  value
})

export const saveDatiConfigurazione = datiConfigurazione => ({
  type: SAVE_DATI_CONFIGURAZIONE,
  datiConfigurazione
})

export const updateDatiConfigurazione = (objKey, value) => ({
  type: UPDATE_DATI_CONFIGURAZIONE,
  objKey,
  value
})

export const saveDatiCgc = datiCgc => ({
  type: SAVE_DATI_CGC,
  datiCgc
})

export const resetDatiWorkflow = () => ({
  type: RESET_DATI_WORKFLOW
})