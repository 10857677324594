import React, { useState, useEffect } from 'react'
import axiosInstance from '../../../api/interceptor';
import {connect} from 'react-redux';
import { toggleSpinner, toggleToast } from '../../../store/actionCreators/general';
import classNames from 'classnames';
import { ValidateEmail } from '../../../components/utils/utils';

function RecuperaPasswordModal({ toggleSpinner, toggleToast }) {
  const [email, setEmail] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);

  async function resetPassword() {
    toggleSpinner()
    await axiosInstance.post('/password-reset-recovery', { email }, { 'Content-Type': 'multipart/form-data' })
      .then(res => {
        console.log(res);
        setSuccessfulSubmit(true);
        toggleSpinner()
      })
      .catch(e => {
        toggleToast('e', e.messaggio)
        toggleSpinner();
      })
  }

  function submitRecuperoPswd() {
    setSubmitted(true);
    if (checkEmail) resetPassword()
  }

  useEffect(() => {
    setCheckEmail(ValidateEmail(email))
  }, [email])

  return (
    <div className="py-3">
      { !successfulSubmit
        ? <>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h6 className="color-bianco text-center">Inserisci l'indirizzo e-mail collegato al tuo account e clicca sul pulsante "Invia"</h6>
          
            <div className="form-group">
              <input type="email" className={classNames("form-control mt-3", { "is-invalid": !checkEmail && submitted })} value={email} onChange={e => setEmail(e.target.value)} />
              <div className={classNames("small mt-1 text-danger font-weight-bold", { 'd-none': (checkEmail && submitted) || !submitted })}>Indirizzo e-mail non valido</div>
            </div>

            
              <div className="btn btn-linkem-red btn-sm border-radius-20 px-4 mt-2" onClick={submitRecuperoPswd}>Invia</div>
          </div>
        </>
        : <div className="d-flex text-center align-items-center">
          <h4 className="color-bianco">Clicca sul link che riceverai al tuo indirizzo e-mail per reimpostare la tua password</h4>
        </div>
      }
    </div>
  )
}

export default connect(null, { toggleSpinner, toggleToast })(RecuperaPasswordModal);
