import { GET_OFFERTE, SET_SELECTED_OFFER } from '../actions/commercialOffers';
import { store } from '../state';

export const getOffers = data => ({
  type: GET_OFFERTE,
  payload: data
})

export const setSelectedOffer = data => ({
  type: SET_SELECTED_OFFER,
  payload: data
});

/**
 * @param {String} offerType
 * Parametri gestiti attualmente: 'offerta-1' | 'offerta-2'
 * Settaggio dell'offerta di default in base alla pagina offerta
 */
export const handleSetSelectedOffer = (offerType) => dispatch => {

  const offerte = store.getState().offerte;
  if (offerte?.length > 0) {
    dispatch(getOffers(offerte));
    dispatch(setSelectedOffer(offerte[0]));
  }
  /*
  let crmOffers = offerte;

  const findOffer = offerType =>
    offerType === 'offerta-2' ? crmOffers.find(({ name }) => name.toUpperCase().includes('HELBIZ')) :
      offerType === 'offerta-1' ? crmOffers.find(({ name }) => !name.toUpperCase().includes('HELBIZ')) :
        console.warn('Default offer not found.');

  if (!crmOffers?.length > 0) {
    getCrmOffer().then(({ data: { data } }) => {
      crmOffers = data;
      if (crmOffers?.length > 0) {
        dispatch(getOffers(crmOffers));
        const find = findOffer(offerType);
        dispatch(setSelectedOffer(find));
      }
    }).catch(e => console.log(e))
  } else {
    const find = findOffer(offerType);
    dispatch(setSelectedOffer(find));
  }
  */
};