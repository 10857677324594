import React from 'react'
import classNames from 'classnames';
import './ButtonLinkem.scss'

/* 
@param linkemRed => sfondo rosso, label bianco / hover = sfondo blu label bianco 
@param linkemBlue => sfondo blu, label bianco / hover = sfondo rosso label bianco 
@param whiteRedLabel => sfondo bianco, label red / hover = sfondo blue label bianco 
@param whiteBlueLabel => sfondo bianco, label blue / hover = sfondo rosso label bianco 
@param polarizedRed => sfondo rosso, label bianco / hover = sfondo bianco label e border rossi
@param polarizedBlue => sfondo blu, label bianco / hover = sfondo bianco label e border blu
*/

function ButtonLinkem({ className, isSmall, linkemRed, linkemBlue, whiteRedLabel, whiteBlueLabel, polarizedRed, polarizedBlue, type, children, ...rest }) {
    return (
        <button
            type={type ? type : 'button'}
            className={
                classNames(
                    'btn',
                    className,
                    { 
                        "btn-sm": isSmall,
                        "button-linkem-red": linkemRed,
                        "button-linkem-blue": linkemBlue,
                        "button-linkem-white-red-label": whiteRedLabel,
                        "button-linkem-white-blue-label": whiteBlueLabel,
                        "button-linkem-polarized-red": polarizedRed,
                        "button-linkem-polarized-blue": polarizedBlue,
                    }
                ) 
            }
            {...rest}
        >
            {children}
        </button>
    )
}

export default ButtonLinkem
